import { Component } from '@angular/core';
import {
  AttendStatus,
  UserRoles,
} from '../../../../../backend/dist/constants/enum';
import { attendService } from '../../../sdk';
import { AuthService } from '../../auth/auth.service';
import { JWTPayload } from '../../../../../backend/dist/common/JWTAuthConfig';
import * as moment from 'moment';
import { Abnormal } from '../../../../../backend/dist/services/AttendService';

@Component({
  selector: 'app-checkinout',
  templateUrl: './checkinout.component.html',
  styleUrls: ['./checkinout.component.scss'],
})
export class CheckinoutComponent {
  message = '';
  schedule_status = '';
  schedule_date = '';

  user?: JWTPayload;
  UserRoles = UserRoles;

  abnormalList!: Abnormal[]

  constructor(public auth: AuthService) {}

  async ngOnInit() {
    let user = this.auth.getCredentials();
    if (!user) return;

    this.user = user;

    let today = new Date().toISOString().split('T')[0];
    let res = await attendService.getV1Schedule({ id: user.id });
    if (res.status === 200) {
      for (let schedule of res.data) {
        if (schedule.dateISO === today) {
          this.schedule_status = schedule.status;
          this.schedule_date = schedule.dateISO;
          break;
        }
      }
    }
    this.message = res.message;
    console.log('res', res);

    await this.checkAbnormal()

  }

  async checkIn() {
    let res = await attendService.getV1Checkin({});
    if (res.status === 200) {
      res.message ||= 'check-in successfully';
    }
    this.message = res.message;
    console.log('res', res);
  }

  async checkOut() {
    let res = await attendService.getV1Checkout({});
    if (res.status === 200) {
      res.message ||= 'check-out successfully';
    }
    this.message = res.message;
    console.log('res', res);
  }

  async checkAbnormal(){

    const res = await attendService.getV1Abnormal({
      month: Number(moment().format("M")),
      user_id: this.auth.getCredentials()?.id!,
      year: Number(moment().format("YYYY"))
    })

    console.log(res);

    this.abnormalList = res.data.abnormal;
  }
}
