import {Component, OnInit} from '@angular/core';
import * as moment from "moment/moment";
import { AuthService } from 'src/app/auth/auth.service';
import {salesService, transService} from "../../../../sdk";
import {SummaryOrderGroupByMonth} from '../../../../../../backend/dist/services/SalesService';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

  constructor(public authService: AuthService) {
  }


  data = {
    labels: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],

    datasets: [
      {
        label: 'GitHub Commits',
        backgroundColor: '#f87979',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    ]
  };

  revenueData!: number[]


  async ngOnInit(): Promise<void> {

    await this.callData(Number(moment().format("YYYY")), this.authService.getCredentials()?.department_id!)
  }

  async callData(year: number, department_id: number) {

    const res = await salesService.getV1View({department_id: department_id, year: year})

    const summaryDepartment: SummaryOrderGroupByMonth[]  = res.data.summaryDepartment

    const revenueData: number[] = []


    summaryDepartment.forEach((ele)=>{

      revenueData.push(ele.total_amount)
    })

    this.revenueData = revenueData

    this.data.datasets = [
      {
        label: '部門銷售訂單總額',
        backgroundColor: '#3498DB',
        data: this.revenueData
      }
      ]
  }

}
