import { Component, OnInit } from '@angular/core';
import { OutGetV1RecordsDTO } from '../../../../../../backend/dist/services/AttendService';
import {AuthService} from "../../../auth/auth.service";

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {


  listOfData: OutGetV1RecordsDTO[] = [];
  filterList: OutGetV1RecordsDTO[] = [];

  constructor(public authService: AuthService) {
  }

  ngOnInit(): void {
  }

}
