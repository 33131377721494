import { Component } from '@angular/core';
import { NzModalRef} from "ng-zorro-antd/modal";
import {AuthService} from "../../../../auth/auth.service";
import * as moment from "moment";

@Component({
  selector: 'app-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss']
})
export class CreateDialogComponent {

  dateFrom!: Date;
  dateTo!: Date;

  constructor(private authService: AuthService, private modalRef: NzModalRef) {
  }

  onCreate(){

    this.modalRef.close({
      date_from: moment(this.dateFrom).format("YYYY-MM-DD"),
      date_to: moment(this.dateTo).format("YYYY-MM-DD"),
      user_id: this.authService!.getCredentials()!.id!
    })
  }

  onCancel(){

    this.modalRef.close()

  }
}
