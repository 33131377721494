import {Component, OnInit} from '@angular/core';
import { ResponseEntity } from '../../../../../../backend/dist/common/responseJSON';
import {LeaveType, Status} from '../../../../../../backend/dist/constants/enum';
import {InPostV1RecordDTO, OutGetV1RecordsDTO} from '../../../../../../backend/dist/services/LeaveService';
import {leaveService, purchaseService, userService} from "../../../../sdk";
import {AuthService} from "../../../auth/auth.service";
import {NzModalService} from "ng-zorro-antd/modal";
import {FormChildDialogComponent} from "../../../shared/form-child-dialog/form-child-dialog.component";
import {LeaveCreateDialogComponent} from "../leave-create-dialog/leave-create-dialog.component";
import * as moment from "moment";

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  status = Status
  leaveType = LeaveType

  constructor(public authService: AuthService, private modalService: NzModalService) {
  }

  listOfColumn = [
    {
      title: '名稱',
      compare: (a: OutGetV1RecordsDTO, b: OutGetV1RecordsDTO) => a.username.localeCompare(b.username),
      priority: 1
    },
    {
      title: '部門',
      compare: (a: OutGetV1RecordsDTO, b: OutGetV1RecordsDTO) => a.department.localeCompare(b.department),
      priority: 2
    },
    {
      title: '職位',
      compare: (a: OutGetV1RecordsDTO, b: OutGetV1RecordsDTO) => a.roleName.localeCompare(b.roleName),
      priority: 3
    },
    {
      title: '類型',
      compare: (a: OutGetV1RecordsDTO, b: OutGetV1RecordsDTO) => a.typeName.localeCompare(b.typeName),
      priority: 4
    },
    {
      title: '請假日期',
      compare: (a: OutGetV1RecordsDTO, b: OutGetV1RecordsDTO) => a.leave_date.localeCompare(b.leave_date),
      priority: 5
    },
    {
      title: '原因',
      compare: (a: OutGetV1RecordsDTO, b: OutGetV1RecordsDTO) => a.reason.localeCompare(b.reason),
      priority: 6
    },
    {
      title: '狀態',
      compare: (a: OutGetV1RecordsDTO, b: OutGetV1RecordsDTO) => a.status_description.localeCompare(b.status_description),
      priority: 7
    }

  ];
  listOfData: OutGetV1RecordsDTO[] = [];
  filterList: OutGetV1RecordsDTO[] = [];
  selectedInput: Status | null = null;

  alLeft: number = 0;

  async ngOnInit(): Promise<void> {

    const r = await leaveService.getV1Al({user_id: this.authService.getCredentials()?.id!})

    if(r.data.total_al){

      this.alLeft = r.data.total_al
    }

    if(this.authService.isAdmin()){

      const res: ResponseEntity<OutGetV1RecordsDTO[]> = await leaveService.getV1Records({})

      this.listOfData = res.data;
      this.filterList = this.listOfData;

    } else {

      const res: ResponseEntity<OutGetV1RecordsDTO[]> = await leaveService.getV1Records({

        user_id: this.authService.getCredentials()?.id!
      })

      this.listOfData = res.data;
      this.filterList = this.listOfData;

    }
  }

  async approval(leave_id: number){

    await leaveService.putV1Record({record_id: leave_id, status: this.status.ACCEPTED})
    await this.ngOnInit()
  }

  async reject(leave_id: number){

    await leaveService.putV1Record({record_id: leave_id, status: this.status.REJECTED})
    await this.ngOnInit()
  }

  onSelect(obj: Status){

    this.filterList = this.listOfData.filter((ele)=>{

      return ele.status == obj
    })
  }
  onCreate(){
    // const dialogRef = this.modalService.create({
    //   nzTitle: '申請休假',
    //   nzWidth: '150vh',
    //   nzContent: LeaveCreateDialogComponent,
    //   nzFooter: null
    // });
    //
    // dialogRef.afterClose.subscribe(async (
    //   ele:{
    //     reason: string,
    //     leave_type: LeaveType,
    //     date: Date,
    //   }) => {
    //
    //   if(ele){
    //
    //     await leaveService.postV1Record({
    //       leave_date: moment(ele.date).toISOString(),
    //       leave_type: ele.leave_type,
    //       reason: ele.reason,
    //       user_id: this.authService.getCredentials()?.id!
    //     })
    //
    //     await this.ngOnInit()
    //   }
    // });

  }

}
