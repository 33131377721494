import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { OutGetV1RecordsDTO } from '../../../../../../backend/dist/services/AttendService';
import {attendService} from "../../../../sdk";
import * as moment from "moment";
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit{

  constructor(public authservice: AuthService) {
  }

  listOfColumn = [
    {
      title: '遲到日數',
      compare: (a: OutGetV1RecordsDTO, b: OutGetV1RecordsDTO) => a.late - b.late,
      priority: 6
    },
    {
      title: '早退日數',
      compare: (a: OutGetV1RecordsDTO, b: OutGetV1RecordsDTO) => a.late - b.late,
      priority: 5
    },
    {
      title: '遲到早退日數',
      compare: (a: OutGetV1RecordsDTO, b: OutGetV1RecordsDTO) => a.late - b.late,
      priority: 4
    },
    {
      title: '請假日數',
      compare: (a: OutGetV1RecordsDTO, b: OutGetV1RecordsDTO) => a.late - b.late,
      priority: 3
    },
    {
      title: '出勤正常日數',
      compare: (a: OutGetV1RecordsDTO, b: OutGetV1RecordsDTO) => a.late - b.late,
      priority: 2
    },
    {
      title: '總排班日數',
      compare: (a: OutGetV1RecordsDTO, b: OutGetV1RecordsDTO) => a.late - b.late,
      priority: 1
    }
  ]


  listOfData: OutGetV1RecordsDTO[] = []

  selectedDate: Date = new Date();

  async ngOnInit(): Promise<void> {

    const res = await attendService.getV1Records({
      month: Number(moment().format("MM")),
      year: Number(moment().format("YYYY"))
    })

    this.listOfData = res.data
  }

  async onDateChange(newDate: Date): Promise<void> {

    const res = await attendService.getV1Records({
      month: Number(moment(newDate).format("MM")),
      year: Number(moment(newDate).format("YYYY"))
    })

    this.listOfData = res.data
  }
}
