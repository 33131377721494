import { Component } from '@angular/core';
import {NzUploadFile} from "ng-zorro-antd/upload";
import {uploadService} from "../../../../../sdk";
import {NzModalRef} from "ng-zorro-antd/modal";
import * as moment from 'moment';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditDialogComponent {

  constructor(private modalRef: NzModalRef ) {
  }

  checkIn!: Date;
  checkOut!: Date;
  uploading = false;

  fileList: NzUploadFile[] = [];

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  async handleUpload(): Promise<void> {

    if(this.fileList.length > 0){
    
      const formData = new FormData();
    
      formData.append('file', this.fileList[0] as any);
    
      this.uploading = true;
    
      const res = await uploadService.postV1File(formData)

      this.modalRef.close({
        file_name: res.data.fileName,
        sign_in_time: moment(this.checkIn).format("HH:mm:ss"),
        sign_out_time: moment(this.checkOut).format("HH:mm:ss")
      });
    }

    this.modalRef.close({
      sign_in_time: moment(this.checkIn).format("HH:mm:ss"),
      sign_out_time: moment(this.checkOut).format("HH:mm:ss")
    });

  }

  onCancel(): void {

    this.modalRef.close();
  }
}
