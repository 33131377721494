import {Component, OnInit} from '@angular/core';
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthService} from "../../../auth/auth.service";
import {attendService, salesService, taskService} from "../../../../sdk";
import {InPostV1ShiftDTO, OutGetV1ShiftrecordDTO} from '../../../../../../backend/dist/services/AttendService';
import {StaffDialogComponent} from "../../../shared/staff-dialog/staff-dialog.component";
import {CreateDialogComponent} from "./create-dialog/create-dialog.component";

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalComponent implements OnInit {

  constructor(private modalService: NzModalService, public authService: AuthService ) {
  }

  filterList!: OutGetV1ShiftrecordDTO[]
  dataList!: OutGetV1ShiftrecordDTO[]

  async ngOnInit(): Promise<void> {

    if(this.authService.isAdmin()){

      const res = await attendService.getV1Shiftrecord({})

      this.dataList = res.data
      this.filterList = this.dataList

    } else {

      const res = await attendService.getV1Shiftrecord({user_id: this.authService.getCredentials()?.id!})

      this.dataList = res.data
      this.filterList = this.dataList
    }
  }

  async onApply(){

    const dialogRef = this.modalService.create({
      nzTitle: '申請換班',
      nzWidth: '150vh',
      nzContent: CreateDialogComponent,
      nzFooter: null
    });

    dialogRef.afterClose.subscribe(async (ele: InPostV1ShiftDTO) => {

      if (ele) {

        await attendService.postV1Shift(ele)
        await this.ngOnInit()
      }
    });
  }

  onAccept(obj: OutGetV1ShiftrecordDTO){


  }

  onReject(obj: OutGetV1ShiftrecordDTO){

  }


}
