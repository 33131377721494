import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {OutsourceComponent} from "../task/outsource/outsource.component";
import { ViewComponent } from './view/view.component';
import {CalendarComponent} from "./calendar/calendar.component";


const routes: Routes = [
  { path: 'view', component: ViewComponent},
  { path: 'calendar', component: CalendarComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectRoutingModule { }
