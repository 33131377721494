import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isCollapsed = false;

  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    const token = localStorage.getItem("token")
    const credentials = this.authService.decodeToken(token)
    if(credentials){
      this.authService.setCredentials(credentials)
      this.authService.rerouteByRole()
    }
  }
}
