import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReimRoutingModule } from './reim-routing.module';
import { ApprovalComponent } from './approval/approval.component';
import { ViewComponent } from './view/view.component';
import {NzTableModule} from "ng-zorro-antd/table";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzFormModule} from "ng-zorro-antd/form";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {NzSelectModule} from "ng-zorro-antd/select";
import { StatisticsComponent } from './statistics/statistics.component';
import {ChartjsComponent} from "@coreui/angular-chartjs";
import { StatisticComponent } from './statistic/statistic.component';



@NgModule({
  declarations: [
    ApprovalComponent,
    ViewComponent,
    StatisticsComponent,
    StatisticComponent,
  ],
    imports: [
        CommonModule,
        ReimRoutingModule,
        NzTableModule,
        NzButtonModule,
        NzModalModule,
        NzFormModule,
        ReactiveFormsModule,
        NzInputModule,
        NzDatePickerModule,
        FormsModule,
        NzSelectModule,
        ChartjsComponent
    ]
})
export class ReimModule { }
