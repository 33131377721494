import { Component, OnInit } from '@angular/core';
import { ResponseEntity } from '../../../../../../backend/dist/common/responseJSON';
import {InPutV1StaffDTO, OutGetV1StaffsDTO} from '../../../../../../backend/dist/services/UserService';
import {salaryService, salesService, taskService, userService} from "../../../../sdk";
import {NzModalService} from "ng-zorro-antd/modal";
import {FormDialogComponent} from "../../../shared/form-dialog/form-dialog.component";
import {EditDialogComponent} from "./edit-dialog/edit-dialog.component";

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  constructor(private modalService: NzModalService) {}

  listOfColumn = [
    {
      title: 'Id',
      compare: (a: OutGetV1StaffsDTO, b: OutGetV1StaffsDTO) => a.id - b.id,
      priority: false
    },
    {
      title: '名稱',
      compare: (a: OutGetV1StaffsDTO, b: OutGetV1StaffsDTO) => a.username.localeCompare(b.username),
      priority: 3
    },
    {
      title: 'Email',
      compare: (a: OutGetV1StaffsDTO, b: OutGetV1StaffsDTO) => a.email.localeCompare(b.email),
      priority: 4
    },
    {
      title: '部門',
      compare: (a: OutGetV1StaffsDTO, b: OutGetV1StaffsDTO) => a.department.localeCompare(b.department),
      priority: 2
    },
    {
      title: '職位',
      compare: (a: OutGetV1StaffsDTO, b: OutGetV1StaffsDTO) => a.roleName.localeCompare(b.roleName),
      priority: 1
    },
    {
      title: '基本薪金',
      compare: (a: OutGetV1StaffsDTO, b: OutGetV1StaffsDTO) => a.salary - b.salary,
      priority: 5
    }
  ];
  listOfData: OutGetV1StaffsDTO[] = [

  ];

  async ngOnInit(): Promise<void> {

    const res: ResponseEntity<OutGetV1StaffsDTO[]> = await userService.getV1Staffs({})

    this.listOfData = res.data;

  }
  handleEdit(obj: OutGetV1StaffsDTO){


    const o: InPutV1StaffDTO = {

      department_id: obj.department_id,
      email: obj.email,
      role: obj.role,
      user_id: obj.id,
      username: obj.username
    }

    const dialogRef = this.modalService.create({
      nzTitle: '修改員工資料',
      nzWidth: '150vh',
      nzContent: EditDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        defaultInputField: o,
        listOfField: [
          {formId: "email", formField: "Email", required: true},
          {formId: "username", formField: "名稱", required: true},
        ]
      }
    });

    dialogRef.afterClose.subscribe(async (ele: InPutV1StaffDTO) => {

      if (ele) {

        await userService.putV1Staff(ele)
        alert("修改成功!")
        await this.ngOnInit()
      }
    });
  }
  handleSalary(obj: OutGetV1StaffsDTO){

    const o = {
      salary: obj.salary,
    }

    const dialogRef = this.modalService.create({
      nzTitle: '調整薪金',
      nzWidth: '150vh',
      nzContent: FormDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        defaultInputField: o,
        listOfField: [
          {formId: "salary", formField: "基本月薪", required: true},
        ]
      }
    });

    dialogRef.afterClose.subscribe(async (ele) => {

      if (ele) {

        await salaryService.putV1Record({salary: ele.salary, user_id: obj.id})
        alert("修改成功!")
        await this.ngOnInit()
      }
    });



  }
}
