import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckingRoutingModule } from './checking-routing.module';
import { ViewComponent } from './view/view.component';
import {NzSelectModule} from "ng-zorro-antd/select";
import {FormsModule} from "@angular/forms";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzButtonModule} from "ng-zorro-antd/button";
import { UploadRefDialogComponent } from './upload-ref-dialog/upload-ref-dialog.component';
import {NzUploadModule} from "ng-zorro-antd/upload";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";



@NgModule({
  declarations: [
    ViewComponent,
    UploadRefDialogComponent
  ],
  imports: [
    CommonModule,
    CheckingRoutingModule,
    NzSelectModule,
    FormsModule,
    NzTableModule,
    NzModalModule,
    NzButtonModule,
    NzUploadModule,
    NzIconModule,
    NzDatePickerModule
  ]
})
export class CheckingModule { }
