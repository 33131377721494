import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import { IncomeComponent } from './income/income.component';
import {BalanceComponent} from "./balance/balance.component";


const routes: Routes = [
  { path: 'balance', component: BalanceComponent },
  { path: 'income', component: IncomeComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class FiscalRoutingModule { }
