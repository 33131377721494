import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {ExpenseComponent} from "./expense/expense.component";
import {IncomeComponent} from "./income/income.component";

const routes: Routes = [
  { path: 'expense', component: ExpenseComponent},
  { path: 'income', component: IncomeComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OtherRoutingModule { }
