import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import { UserRoles } from "../../../../backend/src/constants/enum";
import {inject} from "@angular/core";
import {AuthService} from "./auth.service";

export const canActivateUser: CanActivateFn = (route: ActivatedRouteSnapshot,
                                               state: RouterStateSnapshot) => {

    const authService = inject(AuthService);
    const router = inject(Router);

    if (authService.isLogin()) {

      return true;

    } else {
      return router.parseUrl('/login');
    }

}

export const canActivateByRole = (role: UserRoles): CanActivateFn => {

  return (  route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot) => {

    const authService = inject(AuthService);
    const router = inject(Router);

    const credentials = authService.getCredentials()

    if (credentials?.role == role) {

      return true;

    } else {
      return router.parseUrl('/login');
    }
  }
};
