import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../auth/auth.service";
import * as moment from "moment/moment";
import {salesService} from "../../../../sdk";
import {SumOrderGroupByProjectName} from '../../../../../../backend/dist/services/SalesService';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  constructor(public authService: AuthService) {
  }

  dataList!: SumOrderGroupByProjectName[]
  filteredList!: SumOrderGroupByProjectName[]

  async ngOnInit(): Promise<void> {

    await this.onYearChange(Number(moment().format("YYYY")))
  }

  async onYearChange(year: number) {

    const res = await salesService.getV1View({
      department_id: this.authService.getCredentials()?.department_id!,
      year: year
    })

    this.dataList = res.data.projectAmount
    this.filteredList = this.dataList

  }
}
