import { Component } from '@angular/core';
import {BaseComponent} from "../base/base.component";

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['../base/base.component.scss']
})
export class ManagerComponent extends BaseComponent{

}
