import { Component, OnInit } from '@angular/core';
import {InPostV1RecordDTO, OutGetV1AccountsDTO, OutGetV1RecordsDTO } from '../../../../../../backend/dist/services/BankService';
import {bankService} from "../../../../sdk";
import {NzModalService} from "ng-zorro-antd/modal";
import {CreateDialogComponent} from "../create-dialog/create-dialog.component";



type BankStatement = {

  debt: number | null,
  credit: number | null,
}
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  selectedBank!: OutGetV1AccountsDTO

  bankList!: OutGetV1AccountsDTO[]
  recordList!: (OutGetV1RecordsDTO & BankStatement)[]
  filteredList!: (OutGetV1RecordsDTO & BankStatement)[]

  constructor(private modalService: NzModalService) {
  }

  async ngOnInit(): Promise<void> {

    const l = await bankService.getV1Accounts({})

    this.bankList = l.data

    const r = await bankService.getV1Records({})

    this.recordList = r.data.map((arr) => {

      return {
        debt: arr.amount > 0? null: arr.amount,
        credit: arr.amount < 0? null: arr.amount,
        ...arr
      }
    })

    this.filteredList = this.recordList

  }

  onBankChange(obj: OutGetV1AccountsDTO){

    if(!obj){

      this.filteredList = this.recordList

    } else {

      this.filteredList = this.recordList.filter((arr)=>{

        return arr.bank_id == obj.bank_id
      })
    }
  }

  async onCreate(): Promise<void> {

    const inPostV1RecordDTO: InPostV1RecordDTO = {
      amount: 0, bank_id: 0, description: "", trans_datetime: "", transaction_ref: ""

    }

    const dialogRef = this.modalService.create({
        nzTitle: '創建銀行對帳單',
        nzWidth: '150vh',
        nzContent: CreateDialogComponent,
        nzFooter: null,
        nzComponentParams: {
          defaultInputField: inPostV1RecordDTO,
          listOfField: [
            {formId: "transaction_ref", formField: "交易編號", required: true},
            {formId: "description", formField: "詳細", required: true},
            {formId: "amount", formField: "總額", required: true},
          ],
        }
      }
    )
    dialogRef.afterClose.subscribe(async (ele: InPostV1RecordDTO) => {

      if(ele){

        await bankService.postV1Record(ele);

        alert("Done");
      }
    });
  }


}
