import {Component, OnInit} from '@angular/core';
import { ResponseEntity } from '../../../../../backend/dist/common/responseJSON';
import { OutGetV1StaffsDTO } from '../../../../../backend/dist/services/UserService';
import {userService} from "../../../sdk";
import {NzModalRef} from "ng-zorro-antd/modal";
import { UserRoles } from '../../../../../backend/src/constants/enum';

/**
 * return type: OutGetV1StaffsDTO[]
 */
@Component({
  selector: 'app-staff-dialog',
  templateUrl: './staff-dialog.component.html',
  styleUrls: ['./staff-dialog.component.scss']
})
export class StaffDialogComponent implements OnInit {

  filterRole: UserRoles[] = [];
  filterDepartment: number[] = [];
  listOfData: OutGetV1StaffsDTO[] = [];
  setOfCheckedId = new Set<number>();


  multiChoice = true;

  constructor(private modalRef: NzModalRef) {
  }

  async ngOnInit(): Promise<void> {

    const res: ResponseEntity<OutGetV1StaffsDTO[]> = await userService.getV1Staffs({})

    this.listOfData = res.data.filter((ele) => {

      if(this.filterRole.length != 0) {

        return this.filterRole.includes(ele.role)

      } else {

        return true
      }
    }).filter((ele)=>{

      if(this.filterDepartment.length != 0) {

        return this.filterDepartment.includes(ele.department_id)

      } else {

        return true
      }
    })
  }

  updateCheckedSet(id: number, checked: boolean): void {

    if(this.multiChoice){

      if (checked) {
        this.setOfCheckedId.add(id);
      } else {
        this.setOfCheckedId.delete(id);
      }
    } else {

      this.setOfCheckedId.clear()
      this.setOfCheckedId.add(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
  }

  onConfirm(): void {

    const res = this.listOfData.filter((ele) => {

      return this.setOfCheckedId.has(ele.id)
    })

    this.modalRef.close(res);
  }

  onCancel(): void {

    this.modalRef.close();
  }
}
