import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {OutsourceComponent} from "./outsource/outsource.component";
import {OrderComponent} from "./order/order.component";
import {ViewComponent} from "./view/view.component";



const routes: Routes = [
  { path: 'outsource', component: OutsourceComponent},
  { path: 'order', component: OrderComponent},
  { path: 'view', component: ViewComponent}


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TaskRoutingModule { }
