import { Component, OnInit } from '@angular/core';
import { OutGetV1TasksDTO } from '../../../../../../backend/dist/services/TaskService';
import {salesService, taskService} from "../../../../sdk";
import {FormDialogComponent} from "../../../shared/form-dialog/form-dialog.component";
import {TableDialogComponent} from "../../../shared/table-dialog/table-dialog.component";
import {NzModalService} from "ng-zorro-antd/modal";
import {CustomerSource, CustomerStatus, TaskStatus} from '../../../../../../backend/dist/constants/enum';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  constructor(private modalService: NzModalService) {}

  status = TaskStatus

  filteredList!: OutGetV1TasksDTO[]
  dataList!: OutGetV1TasksDTO[]


  async ngOnInit(): Promise<void> {

    const res = await taskService.getV1Tasks({})

    this.dataList = res.data
    this.filteredList = this.dataList

  }

  async onCreateRemark(data: OutGetV1TasksDTO) {

    const o = {
      note: "",
      last_name: "",
      first_name: "",
      contact_phone: "",
      contact_email: "",
    }

    const dialogRef = this.modalService.create({
      nzTitle: '新增備注',
      nzWidth: '300vh',
      nzContent: FormDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        defaultInputField: o,
        listOfField: [
          {formId: "last_name", formField: "客戶姓氏", required: true},
          {formId: "first_name", formField: "客戶名稱", required: true},
          {formId: "contact_phone", formField: "電話", required: true},
          {formId: "contact_email", formField: "Email", required: true},
          {formId: "note", formField: "內容", required: true},

        ]
      }
    });

    dialogRef.afterClose.subscribe(async (ele) => {

      if (ele) {

        const r = await salesService.postV1Customer({
          contact_email: ele.contact_email,
          contact_phone: ele.contact_phone,
          first_name: ele.first_name,
          last_name: ele.last_name,
          source: CustomerSource.OTHER,
          status: CustomerStatus.EXISTING
        })

        const customer_id = r.data.customer_id

        await taskService.postV1Notes({
          customer_id: customer_id,
          note: ele.note,
          task_id: data.task_id
        })

        await this.ngOnInit()
      }
    });
  }
  async onRemark(data: OutGetV1TasksDTO){

    const res = await taskService.getV1Notes({task_id: data.task_id})

    const tableColumn = [
      {id: "createBy", label:"備注者"},
      {id: "last_name", label:"客戶姓氏"},
      {id: "first_name", label:"客戶名稱"},
      {id: "contact_phone", label:"電話"},
      {id: "contact_email", label:"Email"},
      {id: "note", label:"內容"},
      {id: "created_at", label:"日期"}
    ]
    const tableContent = res.data

    const dialogRef = this.modalService.create({
      nzTitle: '備注一覽',
      nzWidth: '300vh',
      nzContent: TableDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        tableColumn: tableColumn,
        tableContent: tableContent
      }
    });
  }
  async onFinish(data: OutGetV1TasksDTO) {

    await taskService.putV1Status({status: this.status.COMPLETED, task_id: data.task_id})

    await this.ngOnInit()


  }


}
