import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import { AuthService } from 'src/app/auth/auth.service';
import { ReimburseType } from '../../../../../../backend/dist/constants/enum';
import { OutGetV1StatisticsDTO, StatisticsType, StatisticsUser } from '../../../../../../backend/dist/services/ReimburseService';
import {reimburseService} from "../../../../sdk";

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  selectedDate = new Date();

  dataList!: StatisticsUser[]
  filterList!: StatisticsUser[]
  reimTypeList!: StatisticsType[]

  constructor(public authService: AuthService) {
  }


  async ngOnInit(): Promise<void> {

    await this.onDateChange(this.selectedDate)
  }

  async onDateChange(selectDate: Date){

    const res = await reimburseService.getV1Statistics({
      month_MM: moment(selectDate).format("MM"),
      year_YYYY: moment(selectDate).format("YYYY"),
    })

    this.dataList = res.data.user
    this.filterList = this.dataList
    this.reimTypeList = res.data.reimType

    let others = 0
    let transportation = 0
    let travel = 0
    let meal = 0

    this.reimTypeList.forEach((ele)=>{

      if(ele.typeCode == ReimburseType.OTHERS){

        others = ele.reimAmount

      } else if(ele.typeCode == ReimburseType.TRANSPORTATION){

        transportation = ele.reimAmount

      } else if(ele.typeCode == ReimburseType.TRAVEL){

        travel = ele.reimAmount

      } else if(ele.typeCode == ReimburseType.MEAL) {

        meal = ele.reimAmount

      }
    })

    this.data = {
      labels: ['其他', '交通費', '出差費', '餐費'],
      datasets: [{
        backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#F4D03F'],
        data: [others, transportation, travel, meal]
      }]
    }
  }

  data = {
    labels: ['其他', '交通費', '出差費', '餐費'],
    datasets: [{
      backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#F4D03F'],
      data: [0, 0, 0, 0]
    }]
  };
}
