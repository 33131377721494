import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";


const hrRoutes: Routes = [
  { path: 'staffs', loadChildren: () =>
      import('../hr/staffs/staffs.module').then((m) => m.StaffsModule)},
  { path: 'attend', loadChildren: () =>
      import('../hr/attend/attend.module').then((m) => m.AttendModule)},
  { path: 'reim', loadChildren: () =>
      import('../hr/reim/reim.module').then((m) => m.ReimModule)},
  { path: 'leave', loadChildren: () =>
      import('../hr/leave/leave.module').then((m) => m.LeaveModule)},
  { path: 'salary', loadChildren: () =>
      import('../hr/salary/salary.module').then((m) => m.SalaryModule)},


];

@NgModule({
  imports: [RouterModule.forChild(hrRoutes)],
  exports: [RouterModule]
})
export class HrRoutingModule { }
