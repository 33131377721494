import { Component } from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {NzModalRef} from "ng-zorro-antd/modal";

export type FormField = {

  formField: string;
  formId: string;
  required: boolean;
}
@Component({
  selector: 'app-form-child-dialog',
  templateUrl: './form-child-dialog.component.html',
  styleUrls: ['./form-child-dialog.component.scss']
})
export class FormChildDialogComponent {

  editedField: Set<string> = new Set<string>();
  requiredField: Set<string> = new Set<string>();
  filledRequired = false;

  listOfField!: FormField[];
  defaultInputField!: any;

  childFormName!: string;
  listOfChildField!: FormField[];
  defaultInputChildField!: any;

  listOfControl: Array<{ id: number; controlInstance: string }> = [];

  inputForm!: FormGroup;

  constructor(private modalRef: NzModalRef, private fb: FormBuilder) {

  }

  ngOnInit(): void {

    this.inputForm = this.fb.group({
      ...this.defaultInputField
    });

    this.listOfField.forEach((ele) => {

      if(ele.required){

        this.requiredField.add(ele.formId);
      }

      this.inputForm.get(ele.formId)?.valueChanges.subscribe(value => {

        this.filledRequired = true

        this.requiredField.forEach((ele) => {

          const v = this.inputForm.get(ele)?.value

          if(v == 0 || v == ""){

            this.filledRequired = false
          }
        })

        if(value !== this.defaultInputField[ele.formId]){

          this.editedField.add(ele.formId)

        } else if (this.editedField.has(ele.formId)){

          this.editedField.delete(ele.formId)
        }
      });
    })

    this.inputForm.addControl(this.childFormName, this.fb.array([]))
  }

  submitForm(): void {
    if (this.inputForm!.valid) {
      this.modalRef.close(this.inputForm!.value);
    }
  }

  cancel(): void {
    this.modalRef.close();
  }

  get childForm(): FormArray {
    return this.inputForm.get(this.childFormName) as FormArray;
  }

  addContent(e: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }

    this.childForm.push(
      this.fb.group({...this.defaultInputChildField}),
    );
  }




}
