import { Component, OnInit } from '@angular/core';
import { OutGetV1ListDTO, SystemBackup } from '../../../../../../backend/dist/services/BackupService';
import {backupService} from "../../../../sdk";

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  async ngOnInit(): Promise<void> {

    const res = await backupService.getV1List({})

    this.dataList = res.data.systemBackupList
    this.filteredList = this.dataList

  }

  async onCreate(){

    await backupService.getV1Create({})

    alert('備份成功!')
    await this.ngOnInit()
  }

  dataList!: SystemBackup[];
  filteredList!: SystemBackup[];
}
