import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  GetV1RecordsTaskView,
  InPostV1ProjectDTO,
  InPutV1RecordDTO,
  OutGetV1RecordsDTO,
} from '../../../../../../backend/dist/services/ProjectService';
import { StaffDialogComponent } from '../../../shared/staff-dialog/staff-dialog.component';
import { projectService, salesService, taskService } from '../../../../sdk';
import { ResponseEntity } from '../../../../../../backend/dist/common/responseJSON';
import {
  FormDialogComponent,
  FormFieldType,
} from '../../../shared/form-dialog/form-dialog.component';
import {
  CustomerSource,
  CustomerStatus,
  ProjectStatus,
  TaskStatus,
  UserRoles,
} from '../../../../../../backend/src/constants/enum';
import { OutGetV1StaffsDTO } from '../../../../../../backend/dist/services/UserService';
import { AuthService } from 'src/app/auth/auth.service';
import {
  InPostV1TaskDTO,
  OutGetV1TasksDTO,
} from '../../../../../../backend/dist/services/TaskService';
import { CreateTaskDialogComponent } from '../../task/create-task-dialog/create-task-dialog.component';
import { TableDialogComponent } from '../../../shared/table-dialog/table-dialog.component';

type DataTable = {
  expand: boolean;
} & OutGetV1RecordsDTO;

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit {
  taskStatus = TaskStatus;
  projectStatus = ProjectStatus;

  listOfParentData: DataTable[] = [];

  assignedTask: Set<number> = new Set();

  constructor(
    private modalService: NzModalService,
    public authService: AuthService
  ) {}

  async ngOnInit(): Promise<void> {
    const resTask = await taskService.getV1Tasks({});

    resTask.data.forEach((ele) => {
      this.assignedTask.add(ele.task_id);
    });

    const res: ResponseEntity<OutGetV1RecordsDTO[]> =
      await projectService.getV1Records({});

    const dataTableList: DataTable[] = res.data.map((ele) => {
      return { expand: false, ...ele } as DataTable;
    });
    this.listOfParentData = dataTableList;
  }

  openStaffDialog(): void {
    const dialogRef = this.modalService.create({
      nzTitle: 'Staff',
      nzWidth: '300vh',
      nzContent: StaffDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        multiChoice: false,
      },
    });

    dialogRef.afterClose.subscribe((selectedProduct: any) => {
      if (selectedProduct) {
      }
    });
  }
  async onCreateTask(obj: InPutV1RecordDTO): Promise<void> {
    const o: InPostV1TaskDTO = {
      description: '',
      name: '',
      project_id: obj.id,
      visible: 1,
    };

    const dialogRef = this.modalService.create({
      nzTitle: '創建新任務',
      nzWidth: '100vh',
      nzContent: CreateTaskDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        listOfField: [
          { formId: 'name', formField: '任務名稱', required: true },
          { formId: 'description', formField: '詳細', required: true },
        ],
        defaultInputField: o,
      },
    });

    dialogRef.afterClose.subscribe(async (ele: InPostV1TaskDTO) => {
      if (ele) {
        await taskService.postV1Task(ele);
        await this.ngOnInit();
      }
    });
  }

  async onEdit(obj: InPutV1RecordDTO): Promise<void> {
    const dialogRef = this.modalService.create({
      nzTitle: '修改頂目ID: ' + obj.id,
      nzWidth: '50vh',
      nzContent: FormDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        listOfField: [
          { formId: 'name', formField: '名稱', required: true },
          { formId: 'description', formField: '詳細', required: true },
        ],
        defaultInputField: obj,
      },
    });

    dialogRef.afterClose.subscribe(async (ele: InPutV1RecordDTO) => {
      if (ele && ele.id !== 0) {
        await projectService.putV1Record(ele);
        await this.ngOnInit();
      }
    });
  }

  async onCreate(): Promise<void> {
    const inPostV1ProjectDto: InPostV1ProjectDTO = {
      description: '',
      name: '',
    };

    const dialogRef = this.modalService.create({
      nzTitle: '創建新項目',
      nzWidth: '50vh',
      nzContent: FormDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        listOfField: [
          { formId: 'name', formField: '名稱', required: true },
          { formId: 'description', formField: '詳細', required: true },
        ],
        defaultInputField: inPostV1ProjectDto,
      },
    });

    dialogRef.afterClose.subscribe(async (ele: InPostV1ProjectDTO) => {
      if (ele.name !== '' && ele.description !== '') {
        await projectService.postV1Project(ele);
        await this.ngOnInit();
      }
    });
  }

  async assignTask(data: GetV1RecordsTaskView): Promise<void> {
    const dialogRef = this.modalService.create({
      nzTitle: '委派任務',
      nzWidth: '300vh',
      nzContent: StaffDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        multiChoice: true,
        filterRole: [UserRoles.EMPLOYEE],
        filterDepartment: [this.authService.getCredentials()?.department_id!],
      },
    });

    dialogRef.afterClose.subscribe(async (ele: OutGetV1StaffsDTO[]) => {
      if (ele.length > 0) {
        const ids: number[] = [];

        ele.forEach((ele) => {
          ids.push(ele.id);
        });

        await taskService.postV1Assign({
          task_id: data.task_id,
          user_ids: ids,
        });
        await this.ngOnInit();
      }
    });
  }

  async assignProject(project_id: number): Promise<void> {
    const dialogRef = this.modalService.create({
      nzTitle: '委派項目',
      nzWidth: '300vh',
      nzContent: StaffDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        multiChoice: false,
        filterRole: [UserRoles.MANAGER],
      },
    });

    dialogRef.afterClose.subscribe(async (ele: OutGetV1StaffsDTO[]) => {
      if (ele.length == 1) {
        await projectService.postV1Assign({
          project_id: project_id,
          user_id: ele[0].id,
        });
        await this.ngOnInit();
      }
    });
  }
  async onCreateRemark(data: GetV1RecordsTaskView) {
    const o = {
      note: '',
      last_name: '',
      first_name: '',
      contact_phone: '',
      contact_email: '',
      source: '',
      status: '',
    };

    const sourceOptions = [
      { label: '其他', value: 0 },
      { label: 'Facebook', value: 1 },
      { label: 'IG', value: 2 },
      { label: '電視', value: 3 },
      { label: '電話', value: 4 },
    ];

    const statusOptions = [
      { label: '潛在客戶', value: 0 },
      { label: '現有客戶', value: 1 },
    ];

    const dialogRef = this.modalService.create({
      nzTitle: '新增客戶及備注',
      nzWidth: '300vh',
      nzContent: FormDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        defaultInputField: o,
        listOfField: [
          { formId: 'last_name', formField: '客戶姓氏', required: true },
          { formId: 'first_name', formField: '客戶名稱', required: true },
          { formId: 'contact_phone', formField: '電話', required: true },
          { formId: 'contact_email', formField: 'Email', required: true },
          {
            formId: 'source',
            formField: '客戶來源',
            required: true,
            component: FormFieldType.SELECT,
            options: sourceOptions,
          },
          {
            formId: 'status',
            formField: '客戶狀態',
            required: true,
            component: FormFieldType.SELECT,
            options: statusOptions,
          },
          { formId: 'note', formField: '內容', required: true },
        ],
      },
    });

    dialogRef.afterClose.subscribe(async (ele) => {
      if (ele) {
        const r = await salesService.postV1Customer({
          contact_email: ele.contact_email,
          contact_phone: ele.contact_phone,
          first_name: ele.first_name,
          last_name: ele.last_name,
          source: Number(ele.source),
          status: Number(ele.status),
          task_id: data.task_id,
        });

        const customer_id = r.data.customer_id;

        await taskService.postV1Notes({
          customer_id: customer_id,
          note: ele.note,
          task_id: data.task_id,
        });

        await this.ngOnInit();
      }
    });
  }
  async onRemark(data: GetV1RecordsTaskView) {
    const res = await taskService.getV1Notes({ task_id: data.task_id });

    const tableColumn = [
      { id: 'createBy', label: '備注者' },
      { id: 'last_name', label: '客戶姓氏' },
      { id: 'first_name', label: '客戶名稱' },
      { id: 'contact_phone', label: '電話' },
      { id: 'contact_email', label: 'Email' },
      { id: 'source', label: '來源' },
      { id: 'status', label: '客戶狀態' },
      { id: 'note', label: '內容' },
      { id: 'created_at', label: '日期' },
    ];
    const tableContent = res.data;

    const dialogRef = this.modalService.create({
      nzTitle: '客戶及備注一覽',
      nzWidth: '300vh',
      nzContent: TableDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        tableColumn: tableColumn,
        tableContent: tableContent?.map((content) => ({
          ...content,
          source: CustomerSource[content.source],
          status: CustomerSource[content.status],
        })),
      },
    });
  }
  async onFinish(data: GetV1RecordsTaskView) {
    await taskService.putV1Status({
      status: this.taskStatus.COMPLETED,
      task_id: data.task_id,
    });

    await this.ngOnInit();
  }
}
