import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { UserRoles } from '../../../../../backend/src/constants/enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  constructor(private authService: AuthService, private router: Router) {
    this.credentials = { email: '', password: '' };

  }

  credentials: { email: string; password: string };

  errorMessage: string | null = null;

  async onSubmit() {
    const res = await this.authService.login({
      loginId: this.credentials.email,
      password: this.credentials.password,
    });

    if (this.authService.isLogin()) {
      this.authService.rerouteByRole()
    } else {
      this.errorMessage = 'Login failed';
    }
  }
}
