import { Component } from '@angular/core';

function calculateHongKongCorporateTax(netProfit: number): number {
  const firstBracketTaxRate = 0.0825;
  const secondBracketTaxRate = 0.165;
  const firstBracketThreshold = 2000000;

  if (netProfit <= firstBracketThreshold) {
    return netProfit * firstBracketTaxRate;
  } else {
    const firstBracketTax = firstBracketThreshold * firstBracketTaxRate;
    const secondBracketTax = (netProfit - firstBracketThreshold) * secondBracketTaxRate;
    return firstBracketTax + secondBracketTax;
  }
}

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent {

}
