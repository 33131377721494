import {Component, OnInit} from '@angular/core';
import * as moment from "moment";
import {AuthService} from "../../../auth/auth.service";
import {salesService} from "../../../../sdk";
import {SummaryCustomer} from '../../../../../../backend/dist/services/SalesService';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit{


  constructor(public authService: AuthService) {
  }

  dataList!: SummaryCustomer[]
  filteredList!: SummaryCustomer[]

  async ngOnInit(): Promise<void> {

    await this.onYearChange(Number(moment().format("YYYY")))
  }

  async onYearChange(year: number) {

    const res = await salesService.getV1View({
      department_id: this.authService.getCredentials()?.department_id!,
      year: year
    })

    this.dataList = res.data.summaryCustomer
    this.filteredList = this.dataList

  }
}
