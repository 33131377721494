import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin/admin.component';
import { ManagerComponent } from './manager/manager.component';
import { EmployeeComponent } from './employee/employee.component';
import { BaseComponent } from './base/base.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { IconsProviderModule } from '../icons-provider.module';
import { CheckinoutComponent } from './checkinout/checkinout.component';
import {NzTableModule} from "ng-zorro-antd/table";

@NgModule({
  declarations: [
    AdminComponent,
    ManagerComponent,
    EmployeeComponent,
    BaseComponent,
    DashboardComponent,
    CheckinoutComponent,
  ],
    imports: [
        CommonModule,
        DashboardRoutingModule,
        IconsProviderModule,
        NzLayoutModule,
        NzMenuModule,
        NzIconModule,
        NzButtonModule,
        NzTableModule,
    ],
})
export class DashboardModule {}
