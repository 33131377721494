import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {NzModalRef} from "ng-zorro-antd/modal";

export type FormField = {

  formField: string;
  formId: string;
  required: boolean;
}
@Component({
  selector: 'app-create-task-dialog',
  templateUrl: './create-task-dialog.component.html',
  styleUrls: ['./create-task-dialog.component.scss']
})
export class CreateTaskDialogComponent implements OnInit {

  editedField: Set<string> = new Set<string>();
  requiredField: Set<string> = new Set<string>();

  filledRequired = false;

  listOfField!: FormField[];
  inputForm!: FormGroup;
  defaultInputField!: any;

  constructor(private modalRef: NzModalRef, private fb: FormBuilder) {

  }

  ngOnInit(): void {
    this.inputForm = this.fb.group({
      visible: 1,
      ...this.defaultInputField
    });

    this.listOfField.forEach((ele) => {

      if(ele.required){

        this.requiredField.add(ele.formId);
      }
      this.inputForm.get(ele.formId)?.valueChanges.subscribe(value => {

        this.filledRequired = true

        this.requiredField.forEach((ele) => {

          const v = this.inputForm.get(ele)?.value

          if(v == 0 || v == ""){

            this.filledRequired = false
          }
        })

        if(value !== this.defaultInputField[ele.formId]){

          this.editedField.add(ele.formId)

        } else if (this.editedField.has(ele.formId)){

          this.editedField.delete(ele.formId)
        }
      });
    })
  }

  submitForm(): void {
    if (this.inputForm!.valid) {
      this.modalRef.close(this.inputForm!.value);
    }
  }

  cancel(): void {
    this.modalRef.close();
  }
}
