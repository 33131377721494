import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FullCalendarComponent} from "@fullcalendar/angular";
import {NzModalService} from "ng-zorro-antd/modal";
import {
  CalendarOptions,
  DateSpanApi, EventAddArg,
  EventChangeArg,
  EventClickArg,
  EventInput,
  EventRemoveArg
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, {DateClickArg, Draggable, DropArg} from "@fullcalendar/interaction";
import {EventImpl} from "@fullcalendar/core/internal";
import {projectService} from "../../../../sdk";
import { OutGetV1RecordsDTO } from '../../../../../../backend/dist/services/ProjectService';
import * as moment from "moment";

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, AfterViewInit{

  @ViewChild('calendar') calendar!: FullCalendarComponent

  data!: OutGetV1RecordsDTO[]
  events: EventInput[] = [];

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth'
    },
    droppable: true,
    drop: this.handleEventDrop.bind(this),
    dateClick: this.handleDateClick.bind(this),
    eventAdd: this.handleEventAdd.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventAllow: this.handleEventAllow.bind(this),
    eventChange: this.handleEventChange.bind(this),
    eventRemove: this.handleEventRemove.bind(this)
  };
  async ngAfterViewInit() {

    const res = await projectService.getV1Records({})
    this.data = res.data

    this.data.forEach((ele) => {

      const endDate = ele.finished_at ? ele.finished_at : new Date()

      const obj = {
        start: moment(ele.created_at).format("YYYY-MM-DD"),
        end: moment(endDate).format("YYYY-MM-DD"),
        title: ele.projectName}

      this.calendar.getApi().addEvent(obj)
    })
  }

  async ngOnInit() {



  }


  handleEventAllow(span: DateSpanApi, movingEvent: EventImpl | null): boolean{

    return true
  }

  handleEventClick(args: EventClickArg) {

  }

  handleDateClick(arg: DateClickArg) {


  }

  handleEventDrop(arg: DropArg) {

  }

  handleEventAdd(arg: EventAddArg) {


  }

  handleEventChange(arg: EventChangeArg) {


  }

  handleEventRemove(arg: EventRemoveArg) {

  }

  isNaN(obj: number){

    return isNaN(obj)
  }


}
