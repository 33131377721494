import { NzIconModule } from 'ng-zorro-antd/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskModule } from './task/task.module';
import { ProjectModule } from './project/project.module';
import { OperationRoutingModule } from './operation-routing.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TaskModule,
    ProjectModule,
    OperationRoutingModule,
    NzIconModule,
  ],
})
export class OperationModule {}
