import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OtherRoutingModule } from './other-routing.module';
import { IncomeComponent } from './income/income.component';
import { ExpenseComponent } from './expense/expense.component';
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzTableModule} from "ng-zorro-antd/table";
import { CreateDialogComponent } from './create-dialog/create-dialog.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {NzSelectModule} from "ng-zorro-antd/select";



@NgModule({
  declarations: [
    IncomeComponent,
    ExpenseComponent,
    CreateDialogComponent
  ],
  imports: [
    CommonModule,
    OtherRoutingModule,
    NzButtonModule,
    NzTableModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzDatePickerModule,
    NzSelectModule,
    FormsModule
  ]
})
export class OtherModule { }
