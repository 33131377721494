import { NzIconModule } from 'ng-zorro-antd/icon';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HrModule } from './hr/hr.module';
import { OperationModule } from './operation/operation.module';
import { SharedModule } from './shared/shared.module';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { FullCalendarModule } from '@fullcalendar/angular';
import { PurchaseModule } from './purchase/purchase.module';
import { FinancialModule } from './financial/financial.module';
import { AcctModule } from './acct/acct.module';
import {NzUploadModule} from "ng-zorro-antd/upload";
import { SalesModule } from './sales/sales.module';
import { SystemModule } from './system/system.module';

registerLocaleData(zh);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NzTableModule,
    NzUploadModule,
    NzIconModule,
    NzModalModule,
    FullCalendarModule,
    SharedModule,
    AuthModule,
    DashboardModule,
    HrModule,
    OperationModule,
    PurchaseModule,
    FinancialModule,
    AcctModule,
    SalesModule,
    SystemModule,
  ],
  bootstrap: [AppComponent],
  providers: [NzModalService],
})
export class AppModule {}
