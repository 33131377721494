import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {ViewComponent} from "./view/view.component";
import {ScheduleComponent} from "./schedule/schedule.component";

const staffsRoutes: Routes = [
  { path: 'view', component: ViewComponent },
  { path: 'salary', component: ScheduleComponent },

];

@NgModule({
  imports: [RouterModule.forChild(staffsRoutes)],
  exports: [RouterModule]
})

export class StaffsRoutingModule { }
