import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { OutSourceType } from '../../../../../../backend/dist/constants/enum';
import { TConsultant } from '../../../../../../backend/dist/proxy';
import {
  InPostV1ConsultantDTO,
  OutGetV1ConsultantDTO,
} from '../../../../../../backend/dist/services/TaskService';
import { taskService } from '../../../../sdk';
import { FormDialogComponent } from '../../../shared/form-dialog/form-dialog.component';

@Component({
  selector: 'app-outsource',
  templateUrl: './outsource.component.html',
  styleUrls: ['./outsource.component.scss'],
})
export class OutsourceComponent implements OnInit {
  listOfParentData: OutGetV1ConsultantDTO[] = [];

  constructor(private modalService: NzModalService) {}

  async ngOnInit(): Promise<void> {
    const res = await taskService.getV1Consultant({});

    this.listOfParentData = res.data;
  }
  onCreate() {
    const consultant: Omit<TConsultant, 'id' | 'status'> = {
      company: '',
      contact_email: '',
      contact_person: '',
      contact_phone: '',
    };

    const inPostV1ConsultantDTO: InPostV1ConsultantDTO = {
      ...consultant,
      contract_id: 0,
      description: '',
      name: '',
      status: OutSourceType.PENDING,
    };

    const dialogRef = this.modalService.create({
      nzTitle: '創建外判項目',
      nzWidth: '50vh',
      nzContent: FormDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        listOfField: [
          { formId: 'company', formField: '公司名稱', required: true },
          { formId: 'contact_email', formField: 'Email', required: true },
          { formId: 'contact_person', formField: '聯絡人', required: true },
          { formId: 'contact_phone', formField: '電話', required: true },
          { formId: 'name', formField: '外判項目名稱', required: true },
          { formId: 'description', formField: '詳細', required: true },
        ],
        defaultInputField: inPostV1ConsultantDTO,
      },
    });

    dialogRef.afterClose.subscribe(async (ele: InPostV1ConsultantDTO) => {
      if (ele) {
        await taskService.postV1Consultant(ele);
        await this.ngOnInit();
      }
    });
  }
  onEdit() {}
}
