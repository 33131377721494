import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {ApprovalComponent} from "./approval/approval.component";
import { ViewComponent } from './view/view.component';
import {StatisticsComponent} from "./statistics/statistics.component";
import {StatisticComponent} from "./statistic/statistic.component";

const reimRoutes: Routes = [
  { path: 'approval', component: ApprovalComponent },
  { path: 'view', component: ViewComponent },
  { path: 'statistics', component: StatisticsComponent },
  { path: 'statistic', component: StatisticComponent },

];

@NgModule({
  imports: [RouterModule.forChild(reimRoutes)],
  exports: [RouterModule]
})
export class ReimRoutingModule { }
