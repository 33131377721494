import { Component } from '@angular/core';
import {BaseComponent} from "../base/base.component";

@Component({
  selector: 'app-admin',
  templateUrl: '../admin/admin.component.html',
  styleUrls: ['../base/base.component.scss']
})
export class AdminComponent extends BaseComponent {
}
