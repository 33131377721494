import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../auth/auth.service";
import {ActivatedRoute} from "@angular/router";
import {attendService, salaryService} from "../../../../sdk";
import * as moment from "moment/moment";
import { UserRoles } from '../../../../../../backend/dist/constants/enum';
import { OutGetV1RecordDTO } from '../../../../../../backend/dist/services/SalaryService';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {


  userId!: number;
  summary!: OutGetV1RecordDTO;
  selectedDate: Date = moment().subtract(1,'month').toDate();
  period!: string;


  constructor(public authService: AuthService, private route: ActivatedRoute) {
  }


  async ngOnInit(){

    if(this.authService.getCredentials()?.role == UserRoles.ADMIN){

      this.userId = Number(this.route.snapshot.paramMap.get("id"))

    } else {

      this.userId = this.authService.getCredentials()?.id!
    }

    await this.onDateChange(this.selectedDate)
  }

  async onDateChange(newDate: Date) {


    const res = await salaryService.getV1Record({

        month: Number(moment(this.selectedDate).format("MM")),
        user_id: this.userId,
        year: Number(moment(this.selectedDate).format("YYYY"))
      }
    )

    this.period = moment(this.selectedDate).format("YYYY-MM")

    this.summary = res.data;
  }
}
