import { NzIconModule } from 'ng-zorro-antd/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttendRoutingModule } from './attend-routing.module';
import { ViewComponent } from './view/view.component';
import { ShiftComponent } from './shift/shift.component';
import { ApprovalComponent } from './approval/approval.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { FormsModule } from '@angular/forms';
import { DetailsComponent } from './details/details.component';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { CreateDialogComponent } from './approval/create-dialog/create-dialog.component';
import { EditDialogComponent } from './details/edit-dialog/edit-dialog.component';
import {NzTimePickerModule} from "ng-zorro-antd/time-picker";
import {NzUploadModule} from "ng-zorro-antd/upload";
import { StatisticComponent } from './statistic/statistic.component';

@NgModule({
  declarations: [
    ViewComponent,
    ShiftComponent,
    ApprovalComponent,
    DetailsComponent,
    CreateDialogComponent,
    EditDialogComponent,
    StatisticComponent,
  ],
  imports: [
    CommonModule,
    AttendRoutingModule,
    FullCalendarModule,
    NzButtonModule,
    NzTableModule,
    NzIconModule,
    NzDatePickerModule,
    FormsModule,
    NzDescriptionsModule,
    NzTimePickerModule,
    NzUploadModule,
  ],
})
export class AttendModule {}
