import {Component, OnInit} from '@angular/core';
import {NzModalService} from "ng-zorro-antd/modal";
import {purchaseService} from "../../../sdk";
import {
  InPostV1RecordDTO,
  OutGetV1RecordsDTO,
  PurchaseDetail
} from '../../../../../backend/dist/services/PurchaseService';
import {FormChildDialogComponent} from "../../shared/form-child-dialog/form-child-dialog.component";
import { PurchaseStatus } from '../../../../../backend/dist/constants/enum';
import {ReceiptDialogComponent} from "../receipt-dialog/receipt-dialog.component";


export type DataTable = {

  expand: boolean
} & OutGetV1RecordsDTO;

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  listOfParentData: DataTable[] = [];

  status = PurchaseStatus

  constructor(private modalService: NzModalService) {
  }

  async ngOnInit(): Promise<void> {

    const res = await purchaseService.getV1Records({});

    const dataTableList: DataTable[] = res.data.map((ele) => {

      return {expand: false, ...ele} as DataTable
    })

    this.listOfParentData = dataTableList;
  }


  async onCreate(): Promise<void> {

    const inPostV1RecordDTO: Omit<InPostV1RecordDTO, 'purchaseDetails'> = {
      company: "",
      contact_email: "",
      contact_person: "",
      contact_phone: "",
      description: "",
      ref: ""
    }

    const purchaseDetail: Omit<PurchaseDetail, 'subTotal'> = {

      item: "",
      qty: 0,
      rate: 0
    }

    const dialogRef = this.modalService.create({
      nzTitle: '創建新採購項目',
      nzWidth: '50vh',
      nzContent: FormChildDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        defaultInputField: inPostV1RecordDTO,
        listOfField: [
          {formId: "description", formField: "採購項目", required: true},
          {formId: "company", formField: "公司名稱", required: true},
          {formId: "contact_person", formField: "聯絡人", required: true},
          {formId: "contact_email", formField: "Email", required: true},
          {formId: "contact_phone", formField: "電話", required: true},
          {formId: "ref", formField: "參考編號", required: false},
        ],

        childFormName: "purchaseDetails",
        defaultInputChildField: purchaseDetail,
        listOfChildField: [
          {formId: "item", formField: "項目", required: false},
          {formId: "qty", formField: "數量", required: false},
          {formId: "rate", formField: "單價", required: false},
        ],
      }
    });

    dialogRef.afterClose.subscribe(async (ele: InPostV1RecordDTO) => {

      if(ele){

        await purchaseService.postV1Record(ele);

        await this.ngOnInit()
      }
    });
  }

  async onDelete(n: number){

    await purchaseService.putV1Status({purchase_id: n, status: PurchaseStatus.CANCELED})

    await this.ngOnInit()
  }

  async onPrint(obj: DataTable){

    const dialogRef = this.modalService.create<ReceiptDialogComponent>({
      nzWidth: '120vh',
      nzContent: ReceiptDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        dataTable: obj

      }
    });

    await this.ngOnInit()
  }

}
