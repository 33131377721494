import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerRoutingModule } from './customer-routing.module';
import { ViewComponent } from './view/view.component';
import {NzTableModule} from "ng-zorro-antd/table";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzSelectModule} from "ng-zorro-antd/select";
import {NzTypographyModule} from "ng-zorro-antd/typography";
import {FormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    ViewComponent
  ],
  imports: [
    CommonModule,
    CustomerRoutingModule,
    NzTableModule,
    NzButtonModule,
    NzSelectModule,
    NzTypographyModule,
    FormsModule
  ]
})
export class CustomerModule { }
