import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiscalRoutingModule } from './fiscal-routing.module';
import { IncomeComponent } from './income/income.component';
import { BalanceComponent } from './balance/balance.component';
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [IncomeComponent, BalanceComponent],
    imports: [
        CommonModule,
        FiscalRoutingModule,
        NzIconModule,
        NzSelectModule,
        NzInputModule,
        NzButtonModule,
        NzTableModule,
        NzDatePickerModule,
        FormsModule,
    ],
})
export class FiscalModule {}
