import { Component } from '@angular/core';
import {NzUploadFile} from "ng-zorro-antd/upload";
import {NzModalRef} from "ng-zorro-antd/modal";
import {uploadService} from "../../../sdk";

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent {
  uploading = false;
  fileList: NzUploadFile[] = [];

  constructor(private modalRef: NzModalRef) {}

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  async handleUpload(): Promise<void> {

    if(this.fileList.length > 0){

      const formData = new FormData();

      formData.set('file', this.fileList[0] as any);

      this.uploading = true;

      const res = await uploadService.postV1File(formData)

      this.modalRef.close(res.data);
    }
  }

  onCancel(): void {

    this.modalRef.close();
  }

}
