import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatisticRoutingModule } from './statistic-routing.module';
import { ChatComponent } from './chat/chat.component';
import { StaffComponent } from './staff/staff.component';
import { CustomerComponent } from './customer/customer.component';
import { ProjectComponent } from './project/project.component';
import {ChartjsComponent} from "@coreui/angular-chartjs";
import {NzTableModule} from "ng-zorro-antd/table";



@NgModule({
  declarations: [
    ChatComponent,
    StaffComponent,
    CustomerComponent,
    ProjectComponent
  ],
  imports: [
    CommonModule,
    StatisticRoutingModule,
    ChartjsComponent,
    NzTableModule
  ]
})
export class StatisticModule { }
