import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {ViewComponent} from "./view/view.component";
import {ShiftComponent} from "./shift/shift.component";
import {ApprovalComponent} from "./approval/approval.component";
import {DetailsComponent} from "./details/details.component";
import { StatisticComponent } from './statistic/statistic.component';

const attendRoutes: Routes = [
  { path: 'view', component: ViewComponent },
  { path: 'shift', component: ShiftComponent },
  { path: 'approval', component: ApprovalComponent },
  { path: 'details', component: DetailsComponent },
  { path: 'details/:id', component: DetailsComponent },
  { path: 'statistic', component: StatisticComponent },

];

@NgModule({
  imports: [RouterModule.forChild(attendRoutes)],
  exports: [RouterModule]
})

export class AttendRoutingModule { }
