import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffsRoutingModule } from './staffs-routing.module';
import { ViewComponent } from './view/view.component';
import { SalaryComponent } from './salary/salary.component';
import {NzTableModule} from "ng-zorro-antd/table";
import { ScheduleComponent } from './schedule/schedule.component';
import {NzAlertModule} from "ng-zorro-antd/alert";
import {NzCalendarModule} from "ng-zorro-antd/calendar";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import { EditDialogComponent } from './view/edit-dialog/edit-dialog.component';
import {NzFormModule} from "ng-zorro-antd/form";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzSelectModule} from "ng-zorro-antd/select";



@NgModule({
  declarations: [
    ViewComponent,
    SalaryComponent,
    ScheduleComponent,
    EditDialogComponent
  ],
    imports: [
        CommonModule,
        StaffsRoutingModule,
        NzTableModule,
        NzAlertModule,
        NzCalendarModule,
        FormsModule,
        NzDatePickerModule,
        ReactiveFormsModule,
        NzFormModule,
        NzInputModule,
        NzButtonModule,
        NzSelectModule
    ]
})
export class StaffsModule { }
