import {Component, OnInit} from '@angular/core';
import {NzModalRef} from "ng-zorro-antd/modal";
import {FormBuilder} from "@angular/forms";

export type TableDialog = {

  id: string
  label: string
}
@Component({
  selector: 'app-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.scss']
})
export class TableDialogComponent implements OnInit {

  tableColumn!: TableDialog[];
  tableContent: any[] = [];

  constructor(private modalRef: NzModalRef) {

  }

  ngOnInit(): void {
  }


  cancel(): void {
    this.modalRef.close();
  }
}
