import { Injectable } from '@angular/core';
import {attendService, userService} from "../../sdk";
import jwt_decode from 'jwt-decode';

import { LoginDTO } from '../../../../backend/dist/services/UserService';
import { JWTPayload } from '../../../../backend/dist/common/JWTAuthConfig';
import { UserRoles } from '../../../../backend/dist/constants/enum';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router) {}

  private credentials: JWTPayload | null = null;

  isLogin(): boolean {
    return this.credentials != null;
  }

  decodeToken(token: string | null): JWTPayload|null{
    if(token){
      return jwt_decode<JWTPayload>(token)
    }
    return null
  }

  getCredentials(): JWTPayload | null {
    return this.credentials;
  }

  setCredentials(credentials: JWTPayload): void {
    if(credentials){
      this.credentials = credentials;
    }
  }

  isAdmin(): boolean {
    return this.credentials?.role == UserRoles.ADMIN
  }

  isManager(): boolean {
    return this.credentials?.role == UserRoles.MANAGER
  }

  async rerouteByRole(){
    if (this.isLogin()) {
      const userRole = this.getCredentials()?.role;
      switch (userRole) {
        case UserRoles.ADMIN:
          await this.router.navigate(['/dashboard/admin/check-in-out']);
          break;
        case UserRoles.MANAGER:
          await this.router.navigate(['/dashboard/manager/check-in-out']);
          break;
        case UserRoles.EMPLOYEE:
          await this.router.navigate(['/dashboard/employee/check-in-out']);
          break;
        default:
      }
    }
  }

  async login(obj: LoginDTO){
    const res = await userService.postV1Login({loginId: obj.loginId, password: obj.password})
    if(res.status !== 200) return
    localStorage.setItem("token", res.data.authorization)
    const credentials = this.decodeToken(res.data.authorization);
    if(credentials){
      this.setCredentials(credentials)
    }
  }

  async logout(){
    localStorage.removeItem("token")
    await this.router.navigate(['/']);
  }
}
