import { NzIconModule } from 'ng-zorro-antd/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PurchaseRoutingModule } from './purchase-routing.module';
import { ViewComponent } from './view/view.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ReceiptDialogComponent } from './receipt-dialog/receipt-dialog.component';
import {NzDescriptionsModule} from "ng-zorro-antd/descriptions";

@NgModule({
  declarations: [ViewComponent, ReceiptDialogComponent],
    imports: [
        CommonModule,
        PurchaseRoutingModule,
        NzTableModule,
        NzButtonModule,
        NzIconModule,
        NzDescriptionsModule,
    ],
})
export class PurchaseModule {}
