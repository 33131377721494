import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeaveRoutingModule } from './leave-routing.module';
import { ViewComponent } from './view/view.component';
import { ApprovalComponent } from './approval/approval.component';
import {NzTableModule} from "ng-zorro-antd/table";
import { LeaveCreateDialogComponent } from './leave-create-dialog/leave-create-dialog.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzSelectModule} from "ng-zorro-antd/select";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";



@NgModule({
  declarations: [
    ViewComponent,
    ApprovalComponent,
    LeaveCreateDialogComponent,
  ],
  imports: [
    CommonModule,
    LeaveRoutingModule,
    NzTableModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzSelectModule,
    FormsModule,
    NzDatePickerModule
  ]
})
export class LeaveModule { }
