import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcctRoutingModule } from './acct-routing.module';
import { BankingModule } from './banking/banking.module';
import { OtherModule } from './other/other.module';
import { CheckingModule } from './checking/checking.module';
import { BookkeepingModule } from './bookkeeping/bookkeeping.module';
import { TaxModule } from './tax/tax.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AcctRoutingModule,
    BankingModule,
    OtherModule,
    CheckingModule,
    BookkeepingModule,
    TaxModule
  ]
})
export class AcctModule { }
