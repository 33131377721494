import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {AdminComponent} from "./admin/admin.component";
import {ManagerComponent} from "./manager/manager.component";
import {EmployeeComponent} from "./employee/employee.component";
import {canActivateByRole} from "../auth/auth.guard";
import { UserRoles } from '../../../../backend/src/constants/enum';
import {DashboardComponent} from "./dashboard.component";
import { CheckinoutComponent } from './checkinout/checkinout.component';

const dashboardRoutes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    children: [
  { path: 'admin',
    component: AdminComponent,
    canActivate: [canActivateByRole(UserRoles.ADMIN)],
    children: [
      {
        path: 'check-in-out',
        component: CheckinoutComponent
      },
      {
        path: 'hr',
        loadChildren: () =>
          import('../hr/hr.module').then((m) => m.HrModule),
      },
      {
        path: 'purchase',
        loadChildren: () =>
          import('../purchase/purchase.module').then((m) => m.PurchaseModule),
      },
      {
        path: 'operation',
        loadChildren: () =>
          import('../operation/operation.module').then((m) => m.OperationModule),
      },
      {
        path: 'acct',
        loadChildren: () =>
          import('../acct/acct.module').then((m) => m.AcctModule),
      },
      {
        path: 'financial',
        loadChildren: () =>
          import('../financial/financial.module').then((m) => m.FinancialModule),
      },
      {
        path: 'sales',
        loadChildren: () =>
          import('../sales/sales.module').then((m) => m.SalesModule),
      },
      {
        path: 'system',
        loadChildren: () =>
          import('../system/system.module').then((m) => m.SystemModule),
      },
    ],
  },
  { path: 'manager',
    component: ManagerComponent,
    canActivate: [canActivateByRole(UserRoles.MANAGER)],
    children: [
      {
        path: 'check-in-out',
        component: CheckinoutComponent
      },
      {
        path: 'hr',
        loadChildren: () =>
          import('../hr/hr.module').then((m) => m.HrModule),
      },
      {
        path: 'purchase',
        loadChildren: () =>
          import('../purchase/purchase.module').then((m) => m.PurchaseModule),
      },
      {
        path: 'operation',
        loadChildren: () =>
          import('../operation/operation.module').then((m) => m.OperationModule),
      },
      {
        path: 'acct',
        loadChildren: () =>
          import('../acct/acct.module').then((m) => m.AcctModule),
      },
      {
        path: 'financial',
        loadChildren: () =>
          import('../financial/financial.module').then((m) => m.FinancialModule),
      },
      {
        path: 'sales',
        loadChildren: () =>
          import('../sales/sales.module').then((m) => m.SalesModule),
      },
    ],
  },
  { path: 'employee',
    component: EmployeeComponent,
    canActivate: [canActivateByRole(UserRoles.EMPLOYEE)],
    children: [
      {
        path: 'check-in-out',
        component: CheckinoutComponent
      },
      {
        path: 'hr',
        loadChildren: () =>
          import('../hr/hr.module').then((m) => m.HrModule),
      },
      {
        path: 'purchase',
        loadChildren: () =>
          import('../purchase/purchase.module').then((m) => m.PurchaseModule),
      },
      {
        path: 'operation',
        loadChildren: () =>
          import('../operation/operation.module').then((m) => m.OperationModule),
      },
      {
        path: 'acct',
        loadChildren: () =>
          import('../acct/acct.module').then((m) => m.AcctModule),
      },
      {
        path: 'financial',
        loadChildren: () =>
          import('../financial/financial.module').then((m) => m.FinancialModule),
      },
      {
        path: 'sales',
        loadChildren: () =>
          import('../sales/sales.module').then((m) => m.SalesModule),
      },
    ],

  }]
}];

@NgModule({
  imports: [RouterModule.forChild(dashboardRoutes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }
