import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";

const routes: Routes = [
  { path: 'project', loadChildren: () =>
      import('./project/project.module').then((m) => m.ProjectModule)},
  { path: 'task', loadChildren: () =>
      import('./task/task.module').then((m) => m.TaskModule)},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OperationRoutingModule { }
