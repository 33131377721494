import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectRoutingModule } from './project-routing.module';
import { ViewComponent } from './view/view.component';
import {ChartjsComponent} from "@coreui/angular-chartjs";



@NgModule({
  declarations: [
    ViewComponent
  ],
    imports: [
        CommonModule,
        ProjectRoutingModule,
        ChartjsComponent
    ]
})
export class ProjectModule { }
