import {Component, OnInit} from '@angular/core';
import { BalanceSheet } from '../../../../../../backend/dist/services/AccountService';
import {accountService} from "../../../../sdk";
import * as moment from "moment";

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
})
export class BalanceComponent implements OnInit {
  selectedYear: Date = new Date();
  balanceSheet!: BalanceSheet;

  async ngOnInit(): Promise<void> {

    await this.onYearChanged(this.selectedYear)
  }

  async onYearChanged(obj: Date): Promise<void> {

    const res = await accountService.getV1Fiscal({year: Number(moment(obj).format("YYYY"))})

    this.balanceSheet = res.data.balanceSheet
  }
}
