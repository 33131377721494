import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {ChatComponent} from "./chat/chat.component";
import {CustomerComponent} from "./customer/customer.component";
import {StaffComponent} from "./staff/staff.component";
import {ProjectComponent} from "./project/project.component";

const routes: Routes = [
  { path: 'chat', component: ChatComponent},
  { path: 'customer', component: CustomerComponent},
  { path: 'staff', component: StaffComponent},
  { path: 'project', component: ProjectComponent},


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StatisticRoutingModule { }
