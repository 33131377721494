import {Component, OnInit} from '@angular/core';
import {ScheduleComponent} from "../schedule/schedule.component";

@Component({
  selector: 'app-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.scss']
})
export class SalaryComponent {

  ngOnInit(): void {
  }

}
