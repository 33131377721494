import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {NzModalRef} from "ng-zorro-antd/modal";
import {InPostV1RecordDTO, OutGetV1AccountsDTO} from '../../../../../../backend/dist/services/BankService';
import {bankService} from "../../../../sdk";
import * as moment from "moment";

export type FormField = {

  formField: string;
  formId: string;
  required: boolean;
}

@Component({
  selector: 'app-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss']
})
export class CreateDialogComponent implements OnInit {

  bankList!: OutGetV1AccountsDTO[]
  selected!: OutGetV1AccountsDTO

  editedField: Set<string> = new Set<string>();
  requiredField: Set<string> = new Set<string>();

  filledRequired = false;

  listOfField!: FormField[];
  inputForm!: FormGroup;
  defaultInputField!: any;
  defaultType: number | null = null


  constructor(private modalRef: NzModalRef, private fb: FormBuilder) {

  }

  async ngOnInit(): Promise<void> {

    const l = await bankService.getV1Accounts({})

    this.bankList = l.data

    this.inputForm = this.fb.group({
      date: null,
      type: this.defaultType,
      ...this.defaultInputField
    });

    this.listOfField.forEach((ele) => {

      if (ele.required) {

        this.requiredField.add(ele.formId);
      }
      this.inputForm.get(ele.formId)?.valueChanges.subscribe(value => {

        this.filledRequired = true

        this.requiredField.forEach((ele) => {

          const v = this.inputForm.get(ele)?.value

          if (v == 0 || v == "") {

            this.filledRequired = false
          }
        })

        if (value !== this.defaultInputField[ele.formId]) {

          this.editedField.add(ele.formId)

        } else if (this.editedField.has(ele.formId)) {

          this.editedField.delete(ele.formId)
        }
      });
    })
  }

  get date(){

    return this.inputForm.get('date')
  }

  submitForm(): void {
    if (this.inputForm!.valid) {

      const amount = this.inputForm.get('amount')?.value
      const type = this.inputForm.get('type')?.value

      const res: InPostV1RecordDTO = {
        amount: amount * type,
        bank_id: this.selected.bank_id,
        description: this.inputForm.get('description')?.value,
        trans_datetime: moment(this.inputForm.get('date')?.value).toISOString(),
        transaction_ref: this.inputForm.get('transaction_ref')?.value
      }

      this.modalRef.close(res);
    }
  }

  cancel(): void {
    this.modalRef.close();
  }

  onBankChange(obj: OutGetV1AccountsDTO): void {

  }

}
