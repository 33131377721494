import {Component, OnInit} from '@angular/core';
import {NzModalService} from "ng-zorro-antd/modal";
import {accountService} from "../../../../sdk";
import {CreateDialogComponent, OutDialog} from "../create-dialog/create-dialog.component";
import * as moment from "moment/moment";
import {CategoryType, ExpenseStatus, RevenueStatus} from '../../../../../../backend/dist/constants/enum';
import {
  InPostV1IncomeDTO,
  OutGetV1CategoriesDTO,
  OutGetV1IncomeDTO
} from '../../../../../../backend/dist/services/AccountService';

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss']
})
export class IncomeComponent implements OnInit {

  revenueStatus = RevenueStatus

  recordList!: OutGetV1IncomeDTO[]
  filteredList!: OutGetV1IncomeDTO[]
  categoryList!: OutGetV1CategoriesDTO[]


  constructor(private modalService: NzModalService) {
  }

  async ngOnInit(): Promise<void> {

    const r = await accountService.getV1Categories({type: CategoryType.REVENUE})

    this.categoryList = r.data

    const res = await accountService.getV1Income({})

    this.recordList = res.data.map((ele) => {

      ele.amount = ele.amount
      return ele
    })

    this.filteredList = this.recordList
  }

  async onCreate(): Promise<void> {

    const inPostV1IncomeDTO: InPostV1IncomeDTO = {

      ref: "",
      amount: 0,
      category_id: 0,
      description: "",
      revenue_datetime: "",
    }


    const dialogRef = this.modalService.create({
        nzTitle: '創建其他支出',
        nzWidth: '150vh',
        nzContent: CreateDialogComponent,
        nzFooter: null,
        nzComponentParams: {
          defaultInputField: inPostV1IncomeDTO,
          listOfField: [
            {formId: "ref", formField: "參考編號", required: false},
            {formId: "description", formField: "詳細", required: true},
            {formId: "amount", formField: "總額", required: true},
          ],
          categoryInput: this.categoryList
        }
      }
    )

    dialogRef.afterClose.subscribe(async (ele: InPostV1IncomeDTO & OutDialog ) => {

      ele.revenue_datetime = moment(ele.date).toISOString()

      if(ele){

        await accountService.postV1Income(ele)
        await this.ngOnInit();
        alert("Done");
      }
    });


  }

  async onProf(obj: OutGetV1IncomeDTO): Promise<void> {


  }
}
