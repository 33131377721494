import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {NzModalRef} from "ng-zorro-antd/modal";

export enum FormFieldType {
  INPUT = "input",
  SELECT = "select",
}

export type FormFieldSelectOption = {
  label: string;
  value: any
}

export type FormField = {
  formField: string;
  formId: string;
  required: boolean;
  component?: FormFieldType
  options?: FormFieldSelectOption[]
}

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss']
})
export class FormDialogComponent implements OnInit {

  editedField: Set<string> = new Set<string>();
  requiredField: Set<string> = new Set<string>();

  filledRequired = false;

  listOfField!: FormField[];
  inputForm!: FormGroup;
  defaultInputField!: any;

  constructor(private modalRef: NzModalRef, private fb: FormBuilder) {

  }

  ngOnInit(): void {
    this.inputForm = this.fb.group({
      ...this.defaultInputField
    });

    this.listOfField.forEach((ele) => {
      console.log("ele",ele)
      if(ele.required){
        this.requiredField.add(ele.formId);
      }
      
      if(!ele.component) {
        ele.component = FormFieldType.INPUT
      }

      this.inputForm.get(ele.formId)?.valueChanges.subscribe(value => {

        this.filledRequired = true

        this.requiredField.forEach((ele) => {

          const v = this.inputForm.get(ele)?.value

          if(v === null || v === undefined || v === ""){
            this.filledRequired = false
          }
        })

        if(value !== this.defaultInputField[ele.formId]){

          this.editedField.add(ele.formId)

        } else if (this.editedField.has(ele.formId)){

          this.editedField.delete(ele.formId)
        }
      });
    })
  }

  submitForm(): void {
    if (this.inputForm!.valid) {
      this.modalRef.close(this.inputForm!.value);
    }
  }

  cancel(): void {
    this.modalRef.close();
  }
}
