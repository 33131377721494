import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinancialRoutingModule } from './financial-routing.module';
import { ProjectModule } from './project/project.module';
import { FiscalModule } from './fiscal/fiscal.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FinancialRoutingModule,
    ProjectModule,
    FiscalModule,
  ]
})
export class FinancialModule { }
