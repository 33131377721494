import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";

const routes: Routes = [
  { path: 'customer', loadChildren: () =>
      import('./customer/customer.module').then((m) => m.CustomerModule)},
  { path: 'statistic', loadChildren: () =>
      import('./statistic/statistic.module').then((m) => m.StatisticModule)}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SalesRoutingModule { }
