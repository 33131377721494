import {Component, OnInit} from '@angular/core';
import {InPostV1ExpenseDTO, OutGetV1CategoriesDTO, OutGetV1ExpenseDTO} from '../../../../../../backend/dist/services/AccountService';
import {accountService, bankService} from "../../../../sdk";
import {NzModalService} from "ng-zorro-antd/modal";
import {CategoryType, ExpenseStatus} from '../../../../../../backend/dist/constants/enum';
import {CreateDialogComponent, OutDialog} from "../create-dialog/create-dialog.component";
import * as moment from "moment";

@Component({
  selector: 'app-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.scss']
})
export class ExpenseComponent implements OnInit {

  expenseStatus = ExpenseStatus

  recordList!: OutGetV1ExpenseDTO[]
  filteredList!: OutGetV1ExpenseDTO[]
  categoryList!: OutGetV1CategoriesDTO[]


  constructor(private modalService: NzModalService) {
  }

  async ngOnInit(): Promise<void> {

    const r = await accountService.getV1Categories({type: CategoryType.EXPENSES})

    this.categoryList = r.data

    const res = await accountService.getV1Expense({})

    this.recordList = res.data.map((ele) => {

      ele.amount = ele.amount * -1
      return ele
    })

    this.filteredList = this.recordList
  }

  async onCreate(): Promise<void> {

    const inPostV1ExpenseDTO: InPostV1ExpenseDTO = {

      ref: "",
      amount: 0,
      category_id: 0,
      description: "",
      expenses_datetime: "",
    }


    const dialogRef = this.modalService.create({
        nzTitle: '創建其他支出',
        nzWidth: '150vh',
        nzContent: CreateDialogComponent,
        nzFooter: null,
        nzComponentParams: {
          defaultInputField: inPostV1ExpenseDTO,
          listOfField: [
            {formId: "ref", formField: "參考編號", required: false},
            {formId: "description", formField: "詳細", required: true},
            {formId: "amount", formField: "總額", required: true},
          ],
          categoryInput: this.categoryList
        }
      }
    )

    dialogRef.afterClose.subscribe(async (ele: InPostV1ExpenseDTO & OutDialog ) => {

      ele.expenses_datetime = moment(ele.date).toISOString()

      if(ele){

        await accountService.postV1Expense(ele)
        await this.ngOnInit();
        alert("Done");
      }
    });


  }

  async onProf(obj: OutGetV1ExpenseDTO): Promise<void> {


  }
}
