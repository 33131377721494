import {Component, OnInit} from '@angular/core';
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthService} from "../../../auth/auth.service";
import {ActivatedRoute} from "@angular/router";
import {attendService} from "../../../../sdk";
import * as moment from "moment/moment";
import {OutGetV1StatisticDTO
} from '../../../../../../backend/dist/services/AttendService';
import { UserRoles } from '../../../../../../backend/dist/constants/enum';

@Component({
  selector: 'app-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.scss']
})
export class StatisticComponent implements OnInit {

  userId!: number;
  summary!: OutGetV1StatisticDTO;
  selectedDate: Date = new Date();
  period!: string;


  constructor(private modalService: NzModalService ,public authService: AuthService, private route: ActivatedRoute) {
  }

  async ngOnInit(): Promise<void> {
    if(this.authService.getCredentials()?.role == UserRoles.ADMIN){

      this.userId = Number(this.route.snapshot.paramMap.get("id"))

    } else {

      this.userId = this.authService.getCredentials()?.id!
    }

    await this.onDateChange(this.selectedDate)
  }

  async onDateChange(newDate: Date) {


    const res = await attendService.getV1Statistic({

        user_id: this.userId,
        year: Number(moment(this.selectedDate).format("YYYY"))
      }
    )
    this.period = moment(this.selectedDate).format("YYYY-MM")

    this.summary = res.data;
  }

}
