import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";

const routes: Routes = [
  { path: 'banking', loadChildren: () =>
      import('./banking/banking.module').then((m) => m.BankingModule)},
  { path: 'other', loadChildren: () =>
      import('./other/other.module').then((m) => m.OtherModule)},
  { path: 'checking', loadChildren: () =>
      import('./checking/checking.module').then((m) => m.CheckingModule)},
  { path: 'bookkeeping', loadChildren: () =>
      import('./bookkeeping/bookkeeping.module').then((m) => m.BookkeepingModule)},
  { path: 'tax', loadChildren: () =>
      import('./tax/tax.module').then((m) => m.TaxModule)},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AcctRoutingModule { }
