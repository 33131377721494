import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StaffDialogComponent } from './staff-dialog/staff-dialog.component';
import {NzTableModule} from "ng-zorro-antd/table";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzFormModule} from "ng-zorro-antd/form";
import { FormDialogComponent } from './form-dialog/form-dialog.component';
import {ReactiveFormsModule} from "@angular/forms";
import {NzInputModule} from "ng-zorro-antd/input";
import { FormChildDialogComponent } from './form-child-dialog/form-child-dialog.component';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';
import {NzUploadModule} from "ng-zorro-antd/upload";
import {NzIconModule} from "ng-zorro-antd/icon";
import { TableDialogComponent } from './table-dialog/table-dialog.component';



@NgModule({
  declarations: [
    StaffDialogComponent,
    FormDialogComponent,
    FormChildDialogComponent,
    UploadDialogComponent,
    TableDialogComponent
  ],
  imports: [
    CommonModule,
    NzTableModule,
    NzButtonModule,
    NzFormModule,
    ReactiveFormsModule,
    NzInputModule,
    NzUploadModule,
    NzIconModule
  ]
})
export class SharedModule { }

