import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BankingRoutingModule } from './banking-routing.module';
import { ViewComponent } from './view/view.component';
import {NzSelectModule} from "ng-zorro-antd/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzButtonModule} from "ng-zorro-antd/button";
import { CreateDialogComponent } from './create-dialog/create-dialog.component';
import {NzFormModule} from "ng-zorro-antd/form";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzDescriptionsModule} from "ng-zorro-antd/descriptions";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";



@NgModule({
  declarations: [
    ViewComponent,
    CreateDialogComponent
  ],
  imports: [
    CommonModule,
    BankingRoutingModule,
    NzSelectModule,
    FormsModule,
    NzTableModule,
    NzButtonModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzDescriptionsModule,
    NzDatePickerModule
  ]
})
export class BankingModule { }
