import { NzIconModule } from 'ng-zorro-antd/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskRoutingModule } from './task-routing.module';
import { OutsourceComponent } from './outsource/outsource.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { CreateTaskDialogComponent } from './create-task-dialog/create-task-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { OrderComponent } from './order/order.component';
import { ViewComponent } from './view/view.component';

@NgModule({
  declarations: [OutsourceComponent, CreateTaskDialogComponent, OrderComponent, ViewComponent],
    imports: [
        CommonModule,
        TaskRoutingModule,
        NzTableModule,
        NzButtonModule,
        ReactiveFormsModule,
        NzIconModule,
        NzFormModule,
        NzInputModule,
        NzSelectModule,
        FormsModule,
    ],
})
export class TaskModule {}
