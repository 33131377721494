import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../auth/auth.service";
import * as moment from "moment";
import {salesService} from "../../../../sdk";
import {StaffSalesAmount} from '../../../../../../backend/dist/services/SalesService';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {
  constructor(public authService: AuthService) {
  }

  dataList!: StaffSalesAmount[]
  filteredList!: StaffSalesAmount[]

  async ngOnInit(): Promise<void> {

    await this.onYearChange(Number(moment().format("YYYY")))
  }

  async onYearChange(year: number) {

    const res = await salesService.getV1View({
      department_id: this.authService.getCredentials()?.department_id!,
      year: year
    })

    this.dataList = res.data.staffSalesAmount
    this.filteredList = this.dataList

  }
}
