import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalaryRoutingModule } from './salary-routing.module';
import { ViewComponent } from './view/view.component';
import { DetailsComponent } from './details/details.component';
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {FormsModule} from "@angular/forms";
import {NzDescriptionsModule} from "ng-zorro-antd/descriptions";
import {NzTableModule} from "ng-zorro-antd/table";



@NgModule({
  declarations: [
    ViewComponent,
    DetailsComponent
  ],
    imports: [
        CommonModule,
        SalaryRoutingModule,
        NzDatePickerModule,
        FormsModule,
        NzDescriptionsModule,
        NzTableModule
    ]
})
export class SalaryModule { }
