import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrRoutingModule } from './hr-routing.module';
import { StaffsModule } from './staffs/staffs.module';
import { AttendModule } from './attend/attend.module';
import { LeaveModule } from './leave/leave.module';
import { ReimModule } from './reim/reim.module';
import { SalaryModule } from './salary/salary.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HrRoutingModule,
    StaffsModule,
    AttendModule,
    LeaveModule,
    ReimModule,
    SalaryModule
  ]
})
export class HrModule { }
