import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../auth/auth.service";
import {reimburseService} from "../../../../sdk";
import * as moment from "moment/moment";
import { ReimburseType } from '../../../../../../backend/dist/constants/enum';
import { OutGetV1StatisticsDTO, StatisticsType, StatisticsUser } from '../../../../../../backend/dist/services/ReimburseService';
@Component({
  selector: 'app-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.scss']
})
export class StatisticComponent implements OnInit {
  selectedDate = new Date();

  dataList!: StatisticsUser[]
  filterList!: StatisticsUser[]
  reimTypeList!: StatisticsType[]

  constructor(public authService: AuthService) {
  }


  async ngOnInit(): Promise<void> {

    await this.onDateChange(this.selectedDate)
  }

  async onDateChange(selectDate: Date){

    const res = await reimburseService.getV1Statistic({
      year: Number(moment(selectDate).format("YYYY")),
      user_id: this.authService.getCredentials()?.id!
    })

    this.dataList = [res.data.user]
    this.filterList = this.dataList
    this.reimTypeList = res.data.reimType

    let others = 0
    let transportation = 0
    let travel = 0
    let meal = 0

    this.reimTypeList.forEach((ele)=>{

      if(ele.typeCode == ReimburseType.OTHERS){

        others = ele.reimAmount

      } else if(ele.typeCode == ReimburseType.TRANSPORTATION){

        transportation = ele.reimAmount

      } else if(ele.typeCode == ReimburseType.TRAVEL){

        travel = ele.reimAmount

      } else if(ele.typeCode == ReimburseType.MEAL) {

        meal = ele.reimAmount

      }
    })

    this.data = {
      labels: ['其他', '交通費', '出差費', '餐費'],
      datasets: [{
        backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#F4D03F'],
        data: [others, transportation, travel, meal]
      }]
    }
  }

  data = {
    labels: ['其他', '交通費', '出差費', '餐費'],
    datasets: [{
      backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#F4D03F'],
      data: [0, 0, 0, 0]
    }]
  };
}
