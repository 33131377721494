import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {NzModalRef} from "ng-zorro-antd/modal";
import { PurchaseDetail } from '../../../../../backend/dist/services/PurchaseService';
import {DataTable} from "../view/view.component";

@Component({
  selector: 'app-receipt-dialog',
  templateUrl: './receipt-dialog.component.html',
  styleUrls: ['./receipt-dialog.component.scss']
})
export class ReceiptDialogComponent implements OnInit{

  @ViewChild('modalFooter', { static: true }) modalFooter!: TemplateRef<any>;
  dataTable!: DataTable;
  purchaseDetails!: PurchaseDetail[]
  receiptNum: string = moment().format('YYYYMMDDHHmmss')

  constructor(private modalRef: NzModalRef) {


  }


  cancel(): void {
    this.modalRef.close();
  }

  ngOnInit(): void {

    this.modalRef.updateConfig({ nzFooter: this.modalFooter });
    this.purchaseDetails = this.dataTable.purchaseDetails;
  }

  printPage() {
    window.print();
  }

}
