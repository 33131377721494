import {Component, OnInit} from '@angular/core';
import { OutGetV1ReportDTO } from '../../../../../../backend/dist/services/AccountService';
import {accountService} from "../../../../sdk";

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit{

  recordList!: OutGetV1ReportDTO[]
  filteredList!: OutGetV1ReportDTO[]

  dateCompare = (a: OutGetV1ReportDTO, b: OutGetV1ReportDTO) => a.transaction_date.localeCompare(b.transaction_date)


  async ngOnInit(): Promise<void> {

    const res = await accountService.getV1Report({})

    this.recordList = res.data
    this.filteredList = this.recordList
  }


}
