import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/app/auth/auth.service';
import {ReimburseStatus, UserRoles} from '../../../../../../backend/dist/constants/enum';
import {OutGetV1RecordsDTO} from '../../../../../../backend/dist/services/ReimburseService';
import {reimburseService} from "../../../../sdk";
import {NzModalService} from "ng-zorro-antd/modal";
import {FormBuilder, FormGroup} from "@angular/forms";
import * as moment from "moment";

type FormField = {

  formField: string;
  formId: string;
  required: boolean;
}

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  isVisible = false;

  status = ReimburseStatus

  editedField: Set<string> = new Set<string>();
  requiredField: Set<string> = new Set<string>();

  filledRequired = false;

  listOfField: FormField[] = [
    {
      formField: '項目',
      formId: 'description',
      required: true
    },
    {
      formField: '金額',
      formId: 'amount',
      required: true
    },
  ]
  defaultInputField = {

    type: 0,
    date: null,
    description: "",
    amount: 0
  }

  inputForm!: FormGroup;


  constructor(public authService: AuthService, private modalService: NzModalService, private fb: FormBuilder) {
  }

  dataList!: OutGetV1RecordsDTO[]
  filterList!: OutGetV1RecordsDTO[]

  async ngOnInit(): Promise<void> {

    const res = await reimburseService.getV1Records({})

    this.dataList = res.data.filter((ele)=>{

      return ele.user.id == this.authService.getCredentials()?.id
    })

    this.filterList = this.dataList

    this.inputForm = this.fb.group({
      ...this.defaultInputField
    });

    this.listOfField.forEach((ele) => {

      if(ele.required){

        this.requiredField.add(ele.formId);
      }

      this.inputForm.get(ele.formId)?.valueChanges.subscribe(value => {

        this.filledRequired = true

        this.requiredField.forEach((ele) => {

          const v = this.inputForm.get(ele)?.value

          if(v == 0 || v == ""){

            this.filledRequired = false
          }
        })

        if(value !== this.defaultInputField[ele.formId as never]){

          this.editedField.add(ele.formId)

        } else if (this.editedField.has(ele.formId)){

          this.editedField.delete(ele.formId)
        }
      });
    })


  }
  reject(id: number){


  }

  approval(id: number){

  }

  handleCancel(){

    this.isVisible = false
  }

  onCreate() {

    this.isVisible = true
  }
  async submitForm(){

    await reimburseService.postV1Record({
      amount: this.inputForm.value.amount,
      date: moment(this.inputForm.value.date).format('YYYY-MM-DD'),
      description: this.inputForm.value.description,
      type: this.inputForm.value.type
    })
    this.isVisible = false

    this.modalService.closeAll()
    await this.ngOnInit()
  }
  cancel(){
    this.isVisible = false

  }
}
