import {Component, OnInit} from '@angular/core';
import { OutGetV1CustomersDTO } from '../../../../../../backend/dist/services/SalesService';
import {salesService, taskService} from "../../../../sdk";
import {StaffDialogComponent} from "../../../shared/staff-dialog/staff-dialog.component";
import {NzModalService} from "ng-zorro-antd/modal";
import {CustomerSource, CustomerStatus, UserRoles} from '../../../../../../backend/dist/constants/enum';
import {AuthService} from "../../../auth/auth.service";
import { OutGetV1StaffsDTO } from '../../../../../../backend/dist/services/UserService';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  constructor(public authService: AuthService, private modalService: NzModalService) {
  }
  filteredList!: OutGetV1CustomersDTO[]
  dataList!: OutGetV1CustomersDTO[]
  setOfCheckedId = new Set<number>();
  listOfData: readonly OutGetV1CustomersDTO[] = [];
  listOfCurrentPageData: readonly OutGetV1CustomersDTO[] = [];

  selectedOption: CustomerSource | null = null;
  selectedStatus: CustomerStatus | null = null;


  async ngOnInit() {

    const res = await salesService.getV1Customers({})

    this.dataList = res.data

    this.filteredList = this.dataList
  }

  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
  }
  onCurrentPageDataChange(listOfCurrentPageData: readonly OutGetV1CustomersDTO[]): void {
    this.listOfCurrentPageData = listOfCurrentPageData;
  }

  onAssign(){

    const dialogRef = this.modalService.create({
      nzTitle: '分配客戶資源到下線員工',
      nzWidth: '300vh',
      nzContent: StaffDialogComponent,
      nzFooter: null,
      nzComponentParams: {
        multiChoice: false,
        filterRole: [UserRoles.EMPLOYEE],
        filterDepartment:[this.authService.getCredentials()?.department_id!]
      }
    });

    dialogRef.afterClose.subscribe(async (ele: OutGetV1StaffsDTO[]) => {

      if (ele.length == 1) {

        const customer_ids: number[] = []

        this.setOfCheckedId.forEach((ele)=>{

          customer_ids.push(ele)
        })

        await salesService.postV1Assign({customer_ids: customer_ids, user_id: ele[0].id})

        alert("Done !")
        await this.ngOnInit()
      }
    });

  }
  onSelect(){

    this.filteredList = this.dataList.filter((ele)=>{

      return this.selectedOption == null? true: ele.sourceCode == this.selectedOption
    }).filter((ele) =>{

      return this.selectedStatus == null? true: ele.statusCode == this.selectedStatus

    })
  }
}
