import {Component, OnInit} from '@angular/core';
import {transService} from "../../../../sdk";
import * as moment from "moment";
//@import "~@coreui/chartjs/scss/coreui-chartjs";
@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  //styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit{

  data = {
    labels: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],

    datasets: [
      {
        label: 'GitHub Commits',
        backgroundColor: '#f87979',
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    ]
  };

  revenueData!: number[]
  expenseData!: number[]


  async ngOnInit(): Promise<void> {

    await this.callData(Number(moment().format("YYYY")))
  }

  async callData(year: number) {

    const res = await transService.getV1Statistics({year: year})

    this.revenueData = Object.values(res.data.revenue);
    this.expenseData = Object.values(res.data.expense);


    this.data.datasets = [
      {
        label: '收入',
        backgroundColor: '#3498DB',
        data: this.revenueData
      },
      {
        label: '支出',
        backgroundColor: '#f87979',
        data: this.expenseData
      }]
  }
}
