import { Component } from '@angular/core';
import {BalanceSheet, IncomeStatement} from '../../../../../../backend/dist/services/AccountService';
import * as moment from "moment/moment";
import {accountService} from "../../../../sdk";

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss']
})
export class IncomeComponent {

  incomeStatement!: IncomeStatement

  selectedYear: Date = new Date();

  async ngOnInit(): Promise<void> {

    await this.onYearChanged(this.selectedYear)
  }

  async onYearChanged(obj: Date): Promise<void> {


    const res = await accountService.getV1Fiscal({year: Number(moment(obj).format("YYYY"))})

    this.incomeStatement = res.data.incomeStatement
  }
}
