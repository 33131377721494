import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import { DetailsComponent } from './details/details.component';
import {ViewComponent} from "./view/view.component";


const routes: Routes = [
  { path: 'details', component: DetailsComponent },
  { path: 'view', component: ViewComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SalaryRoutingModule { }
