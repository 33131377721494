import { Component } from '@angular/core';
import {NzUploadFile} from "ng-zorro-antd/upload";
import {NzModalRef} from "ng-zorro-antd/modal";
import {uploadService} from "../../../../sdk";

export type OutUploadRefDialog = {

  date: Date
  fileName: string
}

@Component({
  selector: 'app-upload-ref-dialog',
  templateUrl: './upload-ref-dialog.component.html',
  styleUrls: ['./upload-ref-dialog.component.scss']
})
export class UploadRefDialogComponent {
  date: Date | null = null
  uploading = false;
  fileList: NzUploadFile[] = [];

  constructor(private modalRef: NzModalRef) {}

  beforeUpload = (file: NzUploadFile): boolean => {
    this.fileList = this.fileList.concat(file);
    return false;
  };

  async handleUpload(): Promise<void> {

    if(this.fileList.length > 0){

      const formData = new FormData();

      formData.set('file', this.fileList[0] as any);

      this.uploading = true;

      const res = await uploadService.postV1File(formData)
      this.modalRef.close({date: this.date, ...res.data});
    }
  }

  onCancel(): void {

    this.modalRef.close();
  }

}
