import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../auth/auth.service";
import {ActivatedRoute} from "@angular/router";
import {reimburseService} from "../../../../sdk";
import { ReimburseStatus, UserRoles } from '../../../../../../backend/dist/constants/enum';
import {OutGetV1RecordsDTO} from '../../../../../../backend/dist/services/ReimburseService';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.scss']
})
export class ApprovalComponent implements OnInit {
  status = ReimburseStatus

  constructor(public authService: AuthService ) {
  }

  dataList!: OutGetV1RecordsDTO[]
  filterList!: OutGetV1RecordsDTO[]

  async ngOnInit(): Promise<void> {

  const res = await reimburseService.getV1Records({})

    if(this.authService.isAdmin()){

      this.dataList = res.data


    } else if(this.authService.isManager()){

      this.dataList = res.data.filter((ele)=>{

        return ele.user.department_id == this.authService.getCredentials()?.department_id
          && ele.user.role == UserRoles.EMPLOYEE
      })
    } else {

      this.dataList = res.data.filter((ele)=>{

        return ele.user.id == this.authService.getCredentials()?.id
      })
    }

    this.filterList = this.dataList
  }
  async reject(id: number){

    await reimburseService.getV1Rejected({reim_id: id})
    await this.ngOnInit()
  }

  async approval(id: number){

    await reimburseService.getV1Approved({reim_id: id})
    await this.ngOnInit()
  }
}
