import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {ViewComponent} from "./view/view.component";
import {ApprovalComponent} from "./approval/approval.component";

const leaveRoutes: Routes = [
  { path: 'view', component: ViewComponent },
  { path: 'approval', component: ApprovalComponent },

];

@NgModule({
  imports: [RouterModule.forChild(leaveRoutes)],
  exports: [RouterModule]
})
export class LeaveRoutingModule { }
