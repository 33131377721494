import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewComponent } from './view/view.component';
import { BookkeepingRoutingModule } from './bookkeeping-routing.module';
import {NzTableModule} from "ng-zorro-antd/table";



@NgModule({
  declarations: [
    ViewComponent
  ],
  imports: [
    CommonModule,
    BookkeepingRoutingModule,
    NzTableModule,
  ]
})
export class BookkeepingModule { }
