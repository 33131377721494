import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackupRoutingModule } from './backup-routing.module';
import { ViewComponent } from './view/view.component';
import {NzTableModule} from "ng-zorro-antd/table";
import {NzButtonModule} from "ng-zorro-antd/button";



@NgModule({
  declarations: [
    ViewComponent
  ],
  imports: [
    CommonModule,
    BackupRoutingModule,
    NzTableModule,
    NzButtonModule,
  ]
})
export class BackupModule { }
