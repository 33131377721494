import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";

const routes: Routes = [
  { path: 'project', loadChildren: () =>
      import('./project/project.module').then((m) => m.ProjectModule)},
  { path: 'fiscal', loadChildren: () =>
      import('./fiscal/fiscal.module').then((m) => m.FiscalModule)},
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FinancialRoutingModule { }
