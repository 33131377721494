import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesRoutingModule } from './sales-routing.module';
import { CustomerModule } from './customer/customer.module';
import { StatisticModule } from './statistic/statistic.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SalesRoutingModule,
    CustomerModule,
    StatisticModule
  ]
})
export class SalesModule { }
