import { Component } from '@angular/core';
import {BaseComponent} from "../base/base.component";

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['../base/base.component.scss']
})
export class EmployeeComponent extends BaseComponent {

}
