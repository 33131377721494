import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackupModule } from './backup/backup.module';
import { SystemRoutingModule } from './system-routing.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BackupModule,
    SystemRoutingModule
  ]
})
export class SystemModule { }
